import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";

function AnnualLeaveBalance() {
  const [search, setsearch] = useState("");
  const [formData, setFormData] = useState([]);
    useEffect(() => {
        fetchAllLeaves();
    }, [])
    const fetchAllLeaves = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODEAPI_URL}hr_leave/fetch`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (
          response?.data &&
          response?.data?.data &&
          response?.data?.data?.length > 0
        ) {
          setFormData(response?.data?.data);
        }else{setFormData([])}
      } catch (error) {
        console.error("Error fetching clock in location:", error);
      }
    };
    const handleSearch = (e) => {
      setsearch(e.target.value);
    };
  return (
    <div className="mt-4">
      <div className="row gap-3">

      <div className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem'}}>
      <div className="row align-items-center justify-content-center">

      <div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

      <i className="far fa-calendar-alt text-primary" style={{fontSize:'1.5rem'}}></i>
      </div>
      <div className="col-8">
        <p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>50% of leave unbooked</p>
        <span>this leave year</span>
      </div>

      </div>

      </div>


      <div className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem'}}>
      <div className="row align-items-center justify-content-center">

      <div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

      <i className="far fa-clock text-primary" style={{fontSize:'1.5rem'}}></i>
      </div>
      <div className="col-8">
        <p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>9 months remaining</p>
        <span>this leave year</span>
      </div>

      </div>

      </div>



      </div>

      <div className="row mt-3 mb-3">

      <input placeholder="Filter employees" onChange={handleSearch} className="form-control" style={{border:'2px solid rgba(0,0,0,0.3)',width: "500px",color:"black"}} />

      </div>

      <div className="mt-3">
        <table className="table table-striped">
          <thead>
            <tr style={{fontSize:'0.9rem'}}>
              <th scope="col" style={{color:'#073a5d',backgroundColor:'#EBF7FF',borderBottom:'2px solid rgba(0,0,0,0.2)'}}>Name</th>
              <th scope="col" style={{color:'#073a5d',backgroundColor:'#EBF7FF',borderBottom:'2px solid rgba(0,0,0,0.2)'}}>Allowance</th>
              <th scope="col" style={{color:'#073a5d',backgroundColor:'#EBF7FF',borderBottom:'2px solid rgba(0,0,0,0.2)'}}>Current balance</th>
              <th scope="col" style={{color:'#073a5d',backgroundColor:'#EBF7FF',borderBottom:'2px solid rgba(0,0,0,0.2)'}}>Remaining percentage</th>
            </tr>
          </thead>
          <tbody style={{fontSize:'0.9rem'}}>
            {formData && formData.length > 0 ? (
              formData
                  .filter((val, index) => {
                      return search.toLowerCase() === ""
                          ? val
                          : val?.full_name.toLowerCase().includes(search);
                  })
                  .map((data, i) => {
                    return <tr>
                      <td className="text-primary" style={{fontWeight:'500'}}>{data.full_name}</td>
                      <td className="text-dark">{moment(data.start_date).format('ll')}</td>
                      <td className="text-dark">{moment(data.end_date).format('ll')}</td>
                      {/* <td className="text-dark">28 days</td>
                      <td className="text-dark">21 days</td> */}
                      <td className="row">
                        <span className="col-12 text-center">75%</span>

                        <div
                          className="progress"
                          style={{ backgroundColor: "rgba(0,0,0,0.1)",padding:'0',height:'10px' }}
                        >
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            style={{ width: "75%" }}
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                    </tr>
                  })
              ):(<tr>
                <th className="text-center" colSpan={5}>
                  No Records Avialable...
                </th>
              </tr>)
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AnnualLeaveBalance;
