import React from 'react'

export default function Overtime() {
  return (
    <div className='row'>
        <h5 className="col-10 text-dark">Overtime</h5>
        <button className="col-2 btn btn-p m-0 p-1">Log overtime</button>
        <div className="col-6">
          <div className="col-12 border custom-box-overtime">
            <h6 className='text-dark p-3 blue-box'>Time off in lieu (TOIL)</h6>
            <div className="row p-4 pb-3">
              <div className="col-4">TOIL logged</div>
              <div className="col-4">TOIL taken</div>
              <div className="col-4">TOIL balance</div>
            </div>

            <div className="row p-4 py-1">
              <div className="col-4"><h5 className='text-dark'>0h 0m</h5></div>
              <div className="col-4"><h5 className='text-dark'>0h 0m</h5></div>
              <div className="col-4"><h5 className='text-dark'>0h 0m</h5></div>
            </div>

            <div className="row p-4 py-1">
              <div className="col-4"><span>No approved claims</span></div>
              <div className="col-4"><span>No TOIL absences</span></div>
              <div className="col-4"><span>Avialable to take</span></div>
            </div>
            <div className="col-12 text-center"><button className='btn btn-p'>Use TOIL</button></div>
          </div>
        </div>
        <div className="col-6">
          <div className="col-12 border custom-box-overtime">
            <h6 className='text-dark p-3 blue-box'>Payable</h6>
            <div className="row p-4 pb-3">
              <div className="col-4">Overtime logged</div>
              <div className="col-4">Paid</div>
              <div className="col-4">Pending payment</div>
            </div>

            <div className="row p-4 py-1">
              <div className="col-4"><h5 className='text-dark'>0h 0m</h5></div>
              <div className="col-4"><h5 className='text-dark'>0h 0m</h5></div>
              <div className="col-4"><h5 className='text-dark'>0h 0m</h5></div>
            </div>

            <div className="row p-4 py-1">
              <div className="col-4"><span>No approved claims</span></div>
              <div className="col-4"><span>Payment scheduled or paid</span></div>
              <div className="col-4"><span>Approve awaiting payment</span></div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="accordion col-12" id="accordionExample">
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Current & future (0)
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6 className='text-center'><i className='bi bi-sun'></i> Nothing to se here.</h6>
                </div>
              </div>
            </div>
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                history (0)
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6 className='text-center'><i className='bi bi-sun'></i> Nothing to se here.</h6>
                </div>
              </div>
            </div>
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Cancelled (0)
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6 className='text-center'><i className='bi bi-sun'></i> Nothing to se here.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
