import axios from 'axios';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import TruncatedParagraph from '../../components/TruncatedParagraph/TruncatedParagraph'
import ReactSession from "../../components/ReactSession";

export default function Index() {
  const [search, setsearch] = useState("");
  const [formData, setFormData] = useState([]);
    useEffect(() => {
        fetchLocation();
    }, [])

  const fetchLocation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_appointments/fetch`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        response?.data &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        setFormData(response?.data?.data);
      }else{setFormData([])}
    } catch (error) {
      console.error("Error fetching clock in location:", error);
    }
  };
  const handleSearch = (e) => {
    setsearch(e.target.value);
  };
  const handleTrash = async (appointment_id = 1) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_appointments/delete`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id: appointment_id,
        }
      });
      fetchLocation();
    } catch (error) {
      console.error('Error fetching Appoinment details:', error);
    }
  }
  const handleStatus = async (status,appointment_id = 1) => {
    try {
      const cStatus = status === 0 ?1:0
      await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_appointments/change_status`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          status:cStatus,
          id: appointment_id,
        }
      });
      fetchLocation();
    } catch (error) {
      console.error('Error fetching Time off in lieu details:', error);
    }
  }
  const handleEdit = (appointment_id = 1) => {
    window.location.href = `/Architecture/Hr/Appointments/Request/${appointment_id}`;
  }
  return (
    <>
      <h2>All Appoinments</h2>

      <div>
      <div className="row mt-3">
        <div className="col-3">
          <input
            placeholder="Search by employees name"
            className="form-control"
            style={{
              border: "2px solid rgba(0,0,0,0.3)",
              color: "black",
            }}
            onChange={handleSearch}
          />
        </div>
        <div className="col-2">
          <a href='/Architecture/Hr/Appointments/Request'
            className="btn btn-danger"
          >
            Add new Request
          </a>
          {/* ----------------------- */}
        </div>
      </div>
      <div className="row mt-3">
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "0.9rem" }}>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >Name</th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Email & Phone
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Company
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Appointment Type
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Preferd Date
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                  width: '25%'
                }}
              >
                Details
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="history" style={{ fontSize: "0.9rem" }}>
            {formData && formData.length > 0 ? (
              formData.filter((val) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.first_name.toLowerCase().includes(search) 
                  || val?.last_name.toLowerCase().includes(search)
                  || val?.email_id.toLowerCase().includes(search)
                  || val?.phone_no.toLowerCase().includes(search);
              }).map((data, index) => {
                return (
                    <tr className="collapsed" key={index}>
                      <td className="text-dark">
                        <p className='fw-bold'>{data.first_name} {data.last_name}</p>
                      </td>
                      <td className="text-dark">
                        <p>{data.email_id}<br />{data.phone_no}</p>
                      </td>
                      <td className="text-dark">
                        <p>{data.company_name}</p>
                      </td>
                      <td className="text-dark">
                        <p>{data.account_type} <br />Account: {data.account_no}</p>
                      </td>
                      <td className="text-dark">
                        <p>{moment(data.preferred_start_date).format('LL')} - {moment(data.preferred_end_date).format('LL')}</p>
                      </td>
                      
                      <td className="text-dark">
                        <TruncatedParagraph text={data.details} maxLength={75} maxHeight={45}/>
                      </td>
                      <td className="text-dark">
                      <button className="btn" onClick={()=>handleStatus(data.status,data.id)}>
                        <i className={`fa fa-toggle-${data.status?'on text-success':'off text-danger'}`}></i>
                      </button>
                        <button className=" btn btn-info" onClick={()=>handleEdit(data.id)}>
                          <i className="fa fa-pen"></i>
                        </button>
                        <button className="ms-2 btn btn-primary" onClick={() => handleTrash(data.id)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                );
              })
            ) : (
              <tr>
                <th className="text-center" colSpan={5}>
                  No Records Avialable...
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
    </>
  )
}
