import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";

export default function Absence() {
  const {employee_id} = useParams();
  const navigate = useNavigate();
  const [totalLeave, setTotalLeave] = useState({
    total_holiday: 0,
    annual: 0,
    sickness: 0,
    lateness: 0,
});
const [holiday, setHoliday] = useState([])
const [currentLeave, setCurrentLeave] = useState([])
const [history, setHistory] = useState([])
useEffect(() => {
  fetchEmployeeDetails();
}, []);
function isTodayOrBefore(dateString) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set today's time to midnight for comparison
  const date = new Date(dateString);
  return date < today;
}
const fetchEmployeeDetails = async () => {
  try {
      const axiosConfig = {
          headers: {
              'Content-Type': 'application/json',
          },
          data: { employee_id: employee_id }
      };
      const leaves = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_leave/fetch_by_employee_id`, axiosConfig);
      const total_holidays = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_holidays/fetch_this_year`, axiosConfig);
      let total = 0
      if(total_holidays?.data && total_holidays?.data?.data && total_holidays?.data?.data?.length > 0){
        setHoliday(total_holidays?.data?.data)
        total = total_holidays.data.data.length
      }
      setTotalLeave({total_holiday: total,annual: 0,sickness: 0,lateness: 0});
      if(leaves?.data && leaves?.data?.data && leaves?.data?.data?.length > 0){
        let future = []
        let hisory = []
          leaves?.data?.data?.map(data=>{
              if(data.leave_for === 'casual') setTotalLeave(prevState => ({...prevState,annual: prevState.annual + 1}));
              if(data.leave_for === 'sickness') setTotalLeave(prevState => ({...prevState,sickness: prevState.sickness + 1}));
              if(data.leave_for === 'lateness') setTotalLeave(prevState => ({...prevState,lateness: prevState.lateness + 1}));
              if(isTodayOrBefore(data.leave_start_datetime) && isTodayOrBefore(data.leave_end_datetime)){
                hisory.push(data)
              }
              else{
                future.push(data)
              }
            })
            setHistory(hisory)
            setCurrentLeave(future)
      }
  } catch (error) {
      console.error('Error fetching employee details:', error);
  }
};
let handleAnnualLeave = (id) => {
  navigate(`/Architecture/Hr/Employees/Details/${id}/CreateAnnualLeave`);
}
let handleSickLeave = (id) => {
  navigate(`/Architecture/Hr/Employees/Details/${id}/CreateSickLeave`);
}
let handleLateness = (id) => {
  navigate(`/Architecture/Hr/Employees/Details/${id}/CreateLateness`);
}
  return (
    <div className='row'>
      <div className="col-9">
        <h6>Filter absences</h6>
        <div className="col-3 my-2">
        <select name="" id="" className='form-select'>
          <option value="">All absences</option>
          <option value="">This month</option>
          <option value="">This Year</option>
        </select>
        </div>
      </div>
      <div className="col-3">
        <h6>Leave year</h6>
        <select name="" id="" className='form-select my-2'>
          <option value="">01 Apr 2024 - 01 Mar 2024</option>
        </select>
      </div>
      <div className="col-12 my-2 mb-4">
        <h5 className='text-dark text-center'>All absences</h5>
      </div>
      <div className='col-md-4 text-center b-right'>
        <h6 className='text-dark'>Annual leave to take</h6>
        <h5 className='mt-3 fw-bold text-dark'>{totalLeave.annual} / {totalLeave.total_holiday}</h5>
        <p>days</p>
        <button className='btn btn-p m-0 mt-2 p-1 px-4 btn-sm' onClick={(e) => handleAnnualLeave(employee_id)}>
             Add Annual Leave
        </button>
        <p><a href="#asda">Update carryovar</a></p>
      </div>
      <div className='col-md-4 text-center b-right'>
        <h6 className='text-dark'>Sickness</h6>
        <h5 className='mt-3 fw-bold text-dark'>{totalLeave.sickness}</h5>
        <p>occurrences</p>
        <button className='btn btn-primary mt-2 p-1 px-4 btn-sm' onClick={(e) => handleSickLeave(employee_id)}>
             Add
        </button>
      </div>
      <div className='col-md-4 text-center'>
        <h6 className='text-dark'>Lateness</h6>
        <h5 className='mt-3 fw-bold text-dark'>{totalLeave.lateness}</h5>
        <p>occurrences</p>
        <button className='btn btn-primary mt-2 p-1 px-4 btn-sm' onClick={(e) => handleLateness(employee_id)}>
             Add
        </button>
      </div>
      <div className="row mt-4">
      <h5 className='text-dark my-2'>Absence history</h5>
        <div className="accordion col-12" id="accordionExample">
          <div className="accordion-item my-2">
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Current & future ({currentLeave.length})
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                {currentLeave && currentLeave.length>0?
                  <div className='row'>
                      <table className="table custom-trip-table">
                        <thead>
                          <tr>
                            <th scope="col">Absence for</th>
                            <th scope="col">Type</th>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">Notes</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentLeave.map((data,index)=>{
                            return (
                              <tr key={index}>
                                <td>{data.absence_type}</td>
                                <td>{data.leave_type}</td>
                                <td>{moment(data.leave_start_datetime).format('LL')}</td>
                                <td>{moment(data.leave_end_datetime).format('LL')}</td>
                                <td>{data.remarks}</td>
                              </tr>
                            )
                        })}
                      </tbody>
                    </table>
                  </div>
                :
                <h6 className='text-center'><i className='bi bi-sun'></i> Nothing to se here.</h6>
                }
              </div>
            </div>
          </div>
          <div className="accordion-item my-2">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Absence history ({history.length})
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
              {history && history.length>0?
                  <div className='row'>
                      <table className="table custom-trip-table">
                        <thead>
                          <tr>
                            <th scope="col">Absence for</th>
                            <th scope="col">Type</th>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">Notes</th>
                          </tr>
                        </thead>
                        <tbody>
                          {history.map((data,index)=>{
                            return (
                              <tr key={index}>
                                <td>{data.absence_type}</td>
                                <td>{data.leave_type}</td>
                                <td>{moment(data.leave_start_datetime).format('LL')}</td>
                                <td>{moment(data.leave_end_datetime).format('LL')}</td>
                                <td>{data.remarks}</td>
                              </tr>
                            )
                        })}
                      </tbody>
                    </table>
                  </div>
                :
                <h6 className='text-center'><i className='bi bi-sun'></i> Nothing to se here.</h6>
                }
              </div>
            </div>
          </div>
          <div className="accordion-item my-2">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Public holidays ({holiday.length})
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                {holiday && holiday.length>0?
                  <div className='row'>
                  {holiday.map((data,index)=>{
                    return (
                      <div className="col-12 d-flex align-items-center p-4 h-box" key={index}>
                        <div className="col-1 text-end"><i className='bi bi-sun'></i></div>
                        <div className="col-10 ms-5">
                            <p className='fw-semibold'>{data.type}</p>
                            <p className='fw-bold m-0'>{moment(data.start_date).format('LL')} ({data.total_days} day)</p>
                            <p className='m-0'>{data.name}</p>
                        </div>
                      </div>
                    )
                  })}
                  </div>
                :
                <h6 className='text-center'><i className='bi bi-sun'></i> Nothing to se here.</h6>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
