import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function CreateUser() {
  const { employee_id } = useParams();
  const [formData, setFormData] = useState({
    is_edit: false, 
    employee_id: employee_id, 
    user_type: 'STAFF',
    full_name: '',
    login_username: '',
    password: ''
  });

  useEffect(() => {
    if (employee_id) {
      fetchEmployeeDetails();
    }
  }, [employee_id]);
  const fetchEmployeeDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_single_user_details`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
            employee_id: employee_id,
        }
      });
      const { full_name, user_type, login_username } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : {full_name: '', login_username: '', user_type: ''};
      setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, full_name: full_name, login_username:login_username, password: '', user_type: user_type });
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (formData?.is_edit) {
        // Update existing employee
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_user_details`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData
        });
      } else {
        // Create new employee
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/create_user`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData
        });
      }
      alert('Employee saved successfully!');
    } catch (error) {
      console.error('Error saving employee:', error);
    }
  };

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='card card-primary'>
          <div className='card-header'>
            <h4>{formData?.is_edit ? 'Edit User Credential' : 'Add User Credential'}</h4>
          </div>
          <div className='card-body p-2'>
            <div className='form-group'>
              <label>User Type</label>
              <select
                name='user_type'
                className='form-select'
                value={formData.user_type}
                onChange={handleChange}
              >
                <option value={'ADMIN'}>Admin</option>
                <option value={'STAFF'}>Staff</option>
              </select>
            </div>
            <div className='form-group'>
              <label>Full Name</label>
              <input
                type='text'
                name='full_name'
                className='form-control'
                placeholder='Enter Full Name'
                value={formData.full_name}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Login User Name</label>
              <input
                type='text'
                name='login_username'
                className='form-control'
                placeholder='Enter Login User Name'
                value={formData.login_username}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Login Password</label>
              <input
                type='password'
                name='password'
                className='form-control'
                placeholder='Enter Login Password'
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <button
                className='btn btn-success text-white'
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
