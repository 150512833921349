import React, { useEffect, useRef, useState } from "react";
// import "./Employees.css";
// import ManageEmployee from "./ManageEmployee/ManageEmployee";
// import { Manager } from "socket.io-client";
// import ManageTeams from "./ManageTeams/ManageTeams";
// import VaccinatedEmployees from "./VaccinatedEmployees/VaccinatedEmployees";
// import AnnualLeaveBalance from "./AnnualLeave/AnnualLeaveBalance";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
// import '../../../Firebase'
// import { getFirestore, addDoc, collection, getDocs } from "firebase/firestore"; 

import EmployeeList from './Employee/Index'
import TeamList from './Team/Index'
import AnnualLeaveBalance from "./AnnualLeave/Index";
import ReactSession from "../../components/ReactSession";

import './Employee.css'
function Employees() {
//   const {panel} =  useParams()
  const btns = useRef(null);

//   const db = getFirestore();

//   const [admin,setadmin] = useState({
//     username:'',
//     server_name:'',
//     server_id:'',
//   })
 

  




  const default_btns = [
    {
      id: "Employees",
      link: "manage-employees",
    },
    {
      id: "Manage teams",
      link: "manage-teams",
    },
    
    {
      id: "Annual leave balance",
      link: "annual-leave-balance",
    },
  ];

  const [Panel, setPanel] = useState("Employees");
//   const [StoredValues,setStoredValues] = useState(null)
//   const [Loading,setLoading] = useState(false)
  const [emp,setemp] = useState([])

//   const getDocument = async () => {
//     try {
//       setLoading(true)
//         const docRef = db.collection('servers').doc('admins');
//         const doc = await docRef.get();
//         console.log(doc.data())

//         if (doc.exists) {
//       setLoading(false)

//             return { id: doc.id, data: doc.data() };
//         } else {
//       setLoading(false)

//             return null; // Document doesn't exist
//         }
//     } catch (error) {
//       setLoading(false)

//         console.error("Error getting document:", error);
//         return null;
//     }
// };

// const fetchDataFromFirestore = async () => {
//   try{

//     setLoading(true)

  
//     if(
//       JSON.parse(
//         localStorage.getItem("__react_session__")
//       ).username !== null || JSON.parse(localStorage.getItem("__react_session__")).server_id !== null
//     ){
      
//       const subColRef = await collection(
//         db,
//         "servers",
//         JSON.parse(localStorage.getItem("__react_session__")).server_id,
//         "employees"
//       );
  
//       const qSnap = await getDocs(subColRef);
//       // console.log(qSnap)
//       // const res = await getDocs(subColRef);
//       // console.log(qSnap.docs.map(d => ({id: d.id, ...d.data()})))
//       const newArr = qSnap.docs
//         .map((d) => ({ id: d.id, ...d.data() }))
//         .filter((data, i) => {
//           return (data.adminID = JSON.parse(
//             localStorage.getItem("__react_session__")
//           ).username);
//         });
//       setLoading(false)

//       console.log(newArr);
//       setemp(newArr);
//       // setdata(newArr)
//       console.log(qSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
//     // };
//     }else{
//       setLoading(false)

//       window.location.href = '/SignUp'
//     }
//   }
//   catch(err){
//     console.log(err)
//   }
  
//     };
    
  const handleBtns = (btn) => {
    // console.log(btn);
    // console.log(btns.current.innerText);
    setPanel(btn.id);
    // console.log(document.getElementsByClassName("btn-b"));
    // const arr = [...document.getElementsByClassName("btn-b")];
    // arr.forEach((ele) => {
    //   ele.style.fontWeight = "400";
    //   ele.style.borderBottom = "none";
    // });
    // document.getElementById(`${btn.id}`).style.fontWeight = "600";
    // document.getElementById(`${btn.id}`).style.borderBottom =
    //   "2px solid #073a5d";

    // document.getElementById(`${btn.id}`).style.color = '600'
  };

//   const fetchEmp = async()=>{
//         let response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/api_path/stats`)
//         console.log(response)
//   }

//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem("__react_session__"))
//   setadmin({...admin,username:user.username,server_name:user.server_name,server_id:user.server_id})
//   console.log(user)
  
//     getDocument()
//     if(panel){
//       setPanel(panel);

//     }
//     else{
//       setPanel("Employees");

//     }
//     // fetchEmp()
//     fetchDataFromFirestore()
//   }, []);

  return (
    <div className="emp_p">
      <h2>Employee hub</h2>
      <div className="emp_p_r">
        <div className="btns-emp">
          {default_btns.map((btn, index) => {
            return (
              <>
                <button
                  className={`btn-b panel-tab ${Panel === btn.id?'active':''}`}
                  key={index}
                  onClick={() => handleBtns(btn)}
                  ref={btns}
                  id={btn.id}
                >
                  {btn.id}
                </button>
              </>
            );
          })}
        </div>

        {Panel === "Employees" ? (
          <EmployeeList />
        ) : Panel === "Manage teams" ? (
          <TeamList />
        // ) : Panel === "Vaccinated employees" ? (
          // <VaccinatedEmployees />
        ) : Panel === "Annual leave balance" ? (
          <AnnualLeaveBalance />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Employees;
