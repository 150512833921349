import React, { useState } from "react";
import ReactSession from "../../../components/ReactSession";

function Company() {
  const [mini, setmini] = useState(true);
  const [Onabreakmini, setOnabreakmini] = useState(true);
  const [navItemIndex, setNavItemIndex] = useState(1);

  return (
    <div className="mt-4 text-dark">
      <h4>Company setting</h4>
      <h6 className="mt-4 text-dark">This is where you can change compant's settings</h6>
      <div className="row mt-3">
        <div className="col-3 position-relative">
        <div className="h-100 position-">
          <ul className="nav flex-column nav-underline ">
            <li className="nav-item"><a class={`nav-link ${navItemIndex === 1? 'active':''}`} aria-current="page" href="#companyWideSetting" onClick={()=>setNavItemIndex(1)}>Company wide settings</a></li>
            <li className="nav-item"><a class={`nav-link ${navItemIndex === 2? 'active':''}`} href="#employeeSetting" onClick={()=>setNavItemIndex(2)}>Employee Settingss</a></li>
            <li className="nav-item"><a class={`nav-link ${navItemIndex === 3? 'active':''}`} href="#overtime" onClick={()=>setNavItemIndex(3)}>Overtime and TOIL</a></li>
            <li className="nav-item"><a class={`nav-link ${navItemIndex === 4? 'active':''}`} href="#absenceEntitlement" onClick={()=>setNavItemIndex(4)}>Absence and Entitlement</a></li>
            <li className="nav-item"><a class={`nav-link ${navItemIndex === 5? 'active':''}`} href="#rotas" onClick={()=>setNavItemIndex(5)}>Rotas</a></li>
            <li className="nav-item"><a class={`nav-link ${navItemIndex === 6? 'active':''}`} href="#blipSettings" onClick={()=>setNavItemIndex(6)}>Blip settings</a></li>
          </ul>
        </div>
        </div>
        <div className="col-9">
          <div className="card mb-2" id="companyWideSetting">
            <div className="card-body">
              <h5 className="text-dark">Company wide settings</h5>
              <h6 className="mt-4 text-dark">Company name</h6>
              <button className="btn btn-info mt-3">Whites Training Services</button>
              <h6 className="mt-4 text-dark">Peninsula HR Document Library</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Enable this setting to give your managers acess to the peninsula HR Document Library of expart HR policies, templates, and guides.</p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="company_wide_settings" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="company_wide_settings" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-2" id="employeeSetting">
          <div className="card-body">
              <h5 className="text-dark">Employee settings</h5>
              <h6 className="mt-4 text-dark">Employee status</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Enable the ability for employees to display their current status.</p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="employee_status" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="employee_status" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

              <h6 className="mt-4 text-dark">Hide Email addresses</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Show or hide employee email addresses from other employees, employeeswith payroll permisions are unaffected by this setting. This will affect: </p>
                <ul>
                  <li>The employee hub</li>
                  <li>The contact details on the mobile app</li>
                </ul>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="employee-payroll" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="employee-payroll" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

              <h6 className="mt-4 text-dark">Hide status</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Hide employee details from other employees. Admins and managers are unaffected by this setting. Employees will still be able to see details of their manager(s). This will take effect across all HR products.</p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="employee_hide" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="employee_hide" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

            </div>
          </div>

          <div className="card mb-2" id="overtime">
            <div className="card-body">
              <h5 className="text-dark">Overtime and TOIL</h5>
              <h6 className="mt-4 text-dark">Overtime</h6>
              <p className="mt-3">Overtime refers to any hours worked by an employee that exceed their normal scheduled working hours.</p>
              <h6 className="mt-4 text-dark">Allow Overtime</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Select <b>Enable</b> to allow managers and admins to record additional hours for employees and let staff book absences using the time they've built up if you choose <b>Disable,</b> managers and admins wont`t be able to record any overtime and HR will hide all references to overtime from your account.
                <b>If you have pending overtime requests you won`t be able to manage them if you disable overtime</b>
                </p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="overtime-allow" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="overtime-allow" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

              <h6 className="mt-4 text-dark">Record Overtime</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>
                  By selecting Managers your're allowing only HR manager to record overtime for employees. To let employees record overtime themselves, select Everyone.
                </p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="overtime-record" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="overtime-record" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

              <h6 className="mt-4 text-dark">USE TOIL Overtime</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>
                  By6 selecting <b>Everyone</b> employees will be able to send an absence request using their TOIL balance to their Manager or Admin for approval. By selecting <b>Managers</b>, only HR managers will be able to book TOIL absences on behalf of their employees
                </p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="overtime-use-toil" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="overtime-use-toil" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-2" id="absenceEntitlement">
            <div className="card-body">
              <h5 className="text-dark">Absence and Entitlement</h5>
              <h6 className="mt-4 text-dark">Absence conflict when not in teams </h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>
                If <b>enabled</b>, this setting allows you to also see absence conflicts between employees who haven't been added to a team.
                </p>
                <p>If <b>disabled</b>, employees who haven't been added to a team won't show as absence conflicts with each other,
                </p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="absence-conflict-when-not-base" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="absence-conflict-when-not-base" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

              <h6 className="mt-4 text-dark">Annual leave carryover</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>
                Enables the ability to add a 'carryover' balance for an employee, so they can carry over unused annual leave from one year to the next. You can specify the amount to be carried over for each employee on their profile. N.B. Carryover balance will be used for arinual leave requests if that employee has a carryover balance, even if this switch is disabled.
                </p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="annual-leave-base" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="annual-leave-base" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

              <h6 className="mt-4 text-dark">Prevent employees cancelling future annual leave</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>
                Enabling this setting removes the ability for your employees to delete their own annual leave requests. Their manager or an administrator must take this action on their behalf.
                </p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="prevent-employee-leave-base" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="prevent-employee-leave-base" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-2" id="rotas">
            <div className="card-body">
              <h5 className="text-dark">Rotas</h5>

              <h6 className="mt-4 text-dark">Restricted rota permissions</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>By enabling this setting, you are restricting the edit and delete capabilities of your managers. Once created, rotas can be edited by the author and by admins. Additional managers can be given edit permissions from the rota menu.</p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="restricted-rota-permission" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="restricted-rota-permission" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

              <h6 className="mt-4 text-dark">Hide shift labels in rotas from employees</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Enabling this setting will prevent employees from seeing any colours and labels assigned to shifts on your rotas.</p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="hide-shift-rotas" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="hide-shift-rotas" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>

              <h6 className="mt-4 text-dark">Ability to accept and decline shifts</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Select which employees have the ability to accept or decline shifts by clicking the button on the right. Please note: Amending these settings will only impact shifts assigned after changes are saved.</p>
                </div>
                <div className='col-5'>
                  <button type="button" className="btn btn-danger">Select eligible employees</button>
                </div>
              </div>

              <h6 className="mt-4 text-dark">Activate open shifts</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Enabling this setting will allow employees to assign themselves to available open shifts. Only admins and managers can create open shifts.</p>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="active-open-shift-base" id="option5" autocomplete="off" checked /> Enable</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="active-open-shift-base" id="option6" autocomplete="off" /> Disable</label>
                </div>
              </div>
              
            </div>
          </div>

          <div className="card mb-2" id="blipSettings">
            <div className="card-body">

              <h5 className="text-dark">Blip setting</h5>
              <h6 className="mt-4 text-dark">Flexible clocking</h6>
              <div className="row mt-3">
                <div className='col-7'>
                <p>Let your staff clock in form anywhere without a pre-set location. ideal for freelance or flexible workers. You can open this up to all employees or choose specific employees.</p>
                <a href="/#">Please note Only administrators can adjust this setting</a>
                </div>
                <div className='col-5'>
                  <label className="btn btn-info" for="option5"><input type="radio" className="" name="options-base" id="option5" autocomplete="off" checked /> All</label>
                  <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="options-base" id="option6" autocomplete="off" /> Specific</label>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
