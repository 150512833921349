import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Absence from './DetailsComponents/Absence'
import Employement from './DetailsComponents/Employement'
import Overtime from './DetailsComponents/Overtime'
import Personal from './DetailsComponents/Personal'
import Emergencies from './DetailsComponents/Emergencies'
import Documents from './DetailsComponents/Documents'

export default function Details() {
    const btns = useRef(null);
    const default_btns = [
        {
            id: "Absence",
            link: "details-absence",
            components: Absence,
        },
        {
            id: "Employement",
            link: "details-employement",
            components: Employement,
        },
        {
            id: "Overtime",
            link: "details-overtime",
            components: Overtime,
        },
        {
            id: "Personal",
            link: "details-Personal",
            components: Personal,
        },
        {
            id: "Emergencies",
            link: "details-emergencies",
            components: Emergencies,
        },
        {
            id: "Documents",
            link: "details-documents",
            components: Documents,
        },
    ];
    const [Panel, setPanel] = useState("Absence");
    const handleBtns = (btn) => {
        setPanel(btn.id);
    };
    const {employee_id} = useParams();
    const [employeeData, setEmployeeData] = useState([]);

    const InitialData = [];
    const navigate = useNavigate();

    const [search, setsearch] = useState("");
    const handleSearch = (e) => {
        setsearch(e.target.value);
    };

    useEffect(() => {
        fetchEmployeeDetails();
    }, []);

    const handleEdit = (id = 1) => {
        window.location.href = `/Architecture/Hr/Employees/Create/${id}`;
    }
    const handleAddress = (id = 1) => {
        window.location.href = `/Architecture/Hr/Employees/CreateAddress/${id}`;
    }
    const handleUser = (id = 1) => {
        window.location.href = `/Architecture/Hr/Employees/CreateUser/${id}`;
    }
    const handleTrash = async (id = 1) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/delete_employee`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    employee_id: id,
                }
            });
            fetchEmployeeDetails();
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    }

    const fetchEmployeeDetails = async () => {
        try {
            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: { employee_id: employee_id }
            };
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_single_employee_details`, axiosConfig);
            setEmployeeData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleViewDetails = (id) => {
        // console.log(id);
        navigate(`/Architecture/Hr/Employees/Details/${id}`);
    };
// const handleEmp = (id) => {
    //   console.log(id);
    //   navigate(`/Architecture/Hr/EmployeeProfile/${id}`);
    // };

    return (
        <>
            <div style={{ marginTop: "0.3rem" }}>
                <div className="cards-emp">
                    {employeeData && employeeData.length > 0 ? (
                        employeeData
                            .filter((val, index) => {
                                return search.toLowerCase() === ""
                                    ? val
                                    : val?.full_name.toLowerCase().includes(search);
                            })
                            .map((data, i) => {
                                return (
                                    <>
                                        <div className='col-md-12'>
                                            <div className="card-p" style={{padding:'1rem'}}>
                                                <div style={{width: "100px",height: "100px",borderRadius: "50%"}}
                                                    className="d-flex justify-content-center align-items-center ml-5 col-3 bg-primary"
                                                    >
                                                    <p
                                                        style={{
                                                        fontSize: "1.1rem",
                                                        fontWeight: "500",
                                                        color: "white",
                                                        }}
                                                    >
                                                        {data.full_name
                                                        .replace(
                                                            /^\s*([a-zA-Z]).*\s+([a-zA-Z])\S+$/,
                                                            "$1$2"
                                                        )
                                                        .toUpperCase()}
                                                    </p>
                                                    </div>
                                                <div className='ml-5 w-100'>
                                                    <div className="row w-100 align-items-start">
                                                        <div className="col-6">
                                                            <h2 className='mt-3 fw-bold'>{data.full_name}</h2>
                                                            <p className='fs-5 mt-1 fw-bold'>{data.role}</p>
                                                            <a className='fs-7 fw-bold mt-1 gap-2 d-flex'  href={`mailto:${data.email_id}`} rel="noreferrer"><i class="bi bi-envelope-open"></i>  {data.email_id}</a>
                                                            <a  className='fs-7 fw-bold mt-1 gap-2 d-flex' href={`tel:+${data.phone_code}${data.phone_number}`} rel="noreferrer"><i class="bi bi-telephone-plus"></i>  {data.phone_code} {data.phone_number}</a>
                                                        </div>
                                                        <div className="col-6 mt-auto">
                                                            <div className='ms-auto'>
                                                                <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleEdit(data.id)}><i className='fa fa-edit'></i></button>
                                                                <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleAddress(data.id)}><i className='fa fa-map-marker'></i></button>
                                                                <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleUser(data.id)}><i className='fa fa-lock'></i></button>
                                                                <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleTrash(data.id)}><i className='fa fa-trash'></i></button>
                                                                <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleViewDetails(data.id)}><i className='fa fa-eye'></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-5'>
                                                <div className="col-12">
                                                    <ul className="nav nav-underline gap-0 pt-1 custom-tab">
                                                        {default_btns.map((btn, index) => {
                                                            return (
                                                            <li className="nav-item flex-fill" key={index}>
                                                                <button className={`nav-link w-100 ${Panel === btn.id?'active':''}`} onClick={() => handleBtns(btn)} ref={btns} id={btn.id} data-bs-toggle="tab" data-bs-target={`#${btn.id}-pane`} type="button" role="tab" aria-controls={`#${btn.id}-pane`} aria-selected={Panel === btn.id?'true':'false'}>{btn.id}</button>
                                                            </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className="col-12">
                                                    <div class="tab-content custom-tab-content p-3" id="myTabContent" style={{minHeight: '25rem'}}>
                                                    {default_btns.map((btn, index) => {
                                                            return (
                                                                <div className={`tab-pane fade show ${Panel === btn.id?'active':''}`} id={`#${btn.id}-pane`} role="tabpanel" aria-labelledby={`#${btn.id}`} tabindex={index} key={index}>
                                                                    {<btn.components/>}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
}
