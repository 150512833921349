import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ReactSession from "../../../components/ReactSession";

export default function Holidays() {
    const modalClose = React.useRef(null);
    const modalOpen = React.useRef(null);
   const InitialData = {
    is_edit: false,
    holiday_id: '',
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    total_days: 1,
    type: '',
}
    const [search, setsearch] = useState("");
    const [holidayData, setHolidayData] = useState([]);
    const [formData, setFormData] = useState(InitialData);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSave = async () => {
        try {
            if (formData?.is_edit) {
                // Update existing employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_holidays/update`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            } else {
                // Create new employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_holidays/create`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            }
            setFormData(InitialData)
            modalClose.current?.click();
            alert('Holidays saved successfully!');
            fetchHoliday();
        } catch (error) {
            console.error('Error saving Holidays:', error);
        }
    };
      useEffect(() => {
          fetchHoliday();
      }, [])
  
    const fetchHoliday = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODEAPI_URL}hr_holidays/fetch`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (
          response?.data &&
          response?.data?.data &&
          response?.data?.data?.length > 0
        ) {
          setHolidayData(response?.data?.data);
        }else{setHolidayData([])}
      } catch (error) {
        console.error("Error fetching holidays:", error);
      }
    };
    const handleSearch = (e) => {
      setsearch(e.target.value);
    };
    const handleTrash = async (holiday_id = 1) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_holidays/delete`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            id: holiday_id,
          }
        });
        fetchHoliday();
      } catch (error) {
        console.error('Error fetching Time off in lieu details:', error);
      }
    }
    const handleEdit = (data) => {
        setFormData({
            is_edit: true,
            holiday_id: data.id,
            name: data.name,
            description: data.description,
            start_date: moment(data.start_date).format('YYYY-MM-DD'),
            end_date: moment(data.end_date).format('YYYY-MM-DD'),
            total_days: data.total_days,
            type: data.type,
        });
        modalOpen.current?.click();
    }
    const handleCreate = () =>{
        setFormData(InitialData)
        modalOpen.current?.click();
    }
    const handleStatus = async (status,holiday_id = 1) => {
      try {
        const cStatus = status === 0 ?1:0
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_holidays/change_status`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            status:cStatus,
            id: holiday_id,
          }
        });
        fetchHoliday();
      } catch (error) {
        console.error('Error fetching Time off in lieu details:', error);
      }
    }
    return (
      <div className='mt-4'>
      <h4>All Holidas</h4>
  
      <div>
      <div className="row mt-3">
        <div className="col-3">
          <input
            placeholder="Search by employees name"
            className="form-control"
            style={{
              border: "2px solid rgba(0,0,0,0.3)",
              color: "black",
            }}
            onChange={handleSearch}
          />
        </div>
        <div className="col-2">
          <button
            className="btn btn-danger d-none" data-bs-toggle="modal" data-bs-target="#exampleModal" ref={modalOpen}>
            Add new holidays
          </button>
          <button className="btn btn-danger" onClick={handleCreate}>Add new holidays</button>
          {/* ----------------------- */}
        </div>
      </div>
      <div className="row mt-3">
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "0.9rem" }}>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >Holiday name</th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Description
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Date
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Holiday Type
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="history" style={{ fontSize: "0.9rem" }}>
            {holidayData && holidayData.length > 0 ? (
              holidayData.filter((val) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.full_name.toLowerCase().includes(search) 
              }).map((data, index) => {
                return (
                    <tr className="collapsed" key={index}>
                      <td className="text-dark">
                        <p>{data.name}</p>
                      </td>
                      <td className="text-dark">
                        <p>{data.description}</p>
                      </td>
                      <td className="text-dark">
                        <p>{moment(data.start_date).format('LL')} - {moment(data.end_date).format('LL')}</p>
                        <p>{data.total_days>0?data.total_days+ ' Days':''} </p>
                      </td>
                      <td className="text-dark">
                        <p>{data.type}</p>
                      </td>
                      <td className="text-dark">
                      <button className="btn" onClick={()=>handleStatus(data.status,data.id)}>
                          <i className={`fa fa-toggle-${data.status?'on text-success':'off text-danger'}`}></i>
                        </button>
                        <button className=" btn btn-info" onClick={()=>handleEdit(data)}>
                          <i className="fa fa-pen"></i>
                        </button>
                        <button className="ms-2 btn btn-primary" onClick={() => handleTrash(data.id)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                );
              })
            ) : (
              <tr>
                <th className="text-center" colSpan={5}>
                  No Records Avialable...
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>


    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">{formData.is_edit?'Update holiday':'Create a new holidays'}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div className="row">
                    <div className='col-12'>
                        <label>Holliday Name</label>
                        <input type='text' className='form-control' id="name" name='name' value={formData?.name}
                            onChange={handleChange} />
                    </div>
                    <div className='col-12'>
                        <label>Description</label>
                        <textarea  className='form-control' id="description" name='description' value={formData?.description}
                            onChange={handleChange} ></textarea>
                    </div>
                    <div className='col-12'>
                        <label for='type' className='mt-2 mb-2'>Holiday type</label>
                        <select className='form-select' id='type' name='type' value={formData.type} onChange={handleChange}>
                            <option value={''}> - Select - </option>
                            <option value={'Public Holiday'}> Public Holiday </option>
                            <option value={'Private Leave'}> Private Leave </option>
                            <option value={'Mandatory Leave'}> Mandatory Leave </option>
                            <option value={'Other'}> Other </option>
                        </select>
                    </div>
                    <div className='col-12'>
                        <label>Date</label>
                        <input type='date' className='form-control' id="start_date" name='start_date' value={formData?.start_date}
                            onChange={handleChange} />
                    </div>
                    <div className='col-12'>
                        <label>Total days of leave</label>
                        <input type='number' className='form-control' id="total_days" name='total_days' value={formData?.total_days}
                            onChange={handleChange} />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  ref={modalClose}>Close</button>
                <button type="button" class="btn btn-primary" onClick={handleSave}>Save changes</button>
            </div>
            </div>
        </div>
    </div>
    </div>
    )
  
}
