import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactSession from "../../../components/ReactSession";

function BlipSetting() {
  const [formData, setFormData] = useState({status:1});
  useEffect(() => {
    fetchLocation();
  }, []);
  const fetchLocation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_settings/fetch_single`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: { id: 15 },
        }
      );
      if (
        response?.data &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        setFormData(...response?.data?.data);
      } else {
        setFormData([]);
      }
    } catch (error) {
      console.error("Error fetching clock in location:", error);
    }
    console.log(formData)
  };
  const changeStatus = async (id, status) => {
    await axios.post(
      `${process.env.REACT_APP_NODEAPI_URL}hr_settings/change_status`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        data: { id: id, status: status },
      }
    );
    fetchLocation()
  };

  return (
    <div className="mt-4">
      <h4>Blip setting</h4>
      <h6 className="mt-4">Flexible clocking</h6>
      <div className="row mt-3">
        <div className="col-7">
          <p>
            Let your staff clock in form anywhere without a pre-set location.
            ideal for freelance or flexible workers. You can open this up to all
            employees or choose specific employees.
          </p>
          <a href="/#">
            Please note Only administrators can adjust this setting
          </a>
        </div>
        <div className="col-5">
          <label className="btn btn-info" for="option5">
            <input
              type="radio"
              name="options-base"
              id="option5"
              autocomplete="off"
              checked={formData?.status === 1?'checked':''}
              onClick={()=>changeStatus(15,1)}
            />
            All
          </label>
          <label className="btn btn-info ms-3" for="option6">
            <input
              type="radio"
              name="options-base"
              id="option6"
              autocomplete="off"
              checked={formData?.status === 0?'checked':''}
              onClick={()=>changeStatus(15,0)}
            />
            Specific 
          </label>
        </div>
      </div>
      <div className="d-flex mt-3">
        <button className="btn btn-primary">Cancle</button>
        <button className="btn btn-secondary ms-2">Confirm</button>
      </div>
    </div>
  );
}

export default BlipSetting;
