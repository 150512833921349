import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import Popup from 'reactjs-popup';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function List() {
  let query = useQuery();
  let isSearch = query.get("s")?query.get("s"):'';

  const closeModal = useRef(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [modalProfileData, setModalProfileData] = useState([]);

  const InitialData = [];
  const navigate = useNavigate();

  const [search, setsearch] = useState(isSearch);
  const handleSearch = (e) => {
    setsearch(e.target.value);
  };

  useEffect(() => {
      fetchEmployeeDetails();
  }, []);

  const handleEdit = (employee_id = 1) => {
    closeModal.current.click();
    window.location.href = `/Architecture/Hr/Employees/Create/${employee_id}`;
  }
  const handleAddress = (employee_id = 1) => {
     closeModal.current.click();
    window.location.href = `/Architecture/Hr/Employees/CreateAddress/${employee_id}`;
  }
  const handleUser = (employee_id = 1) => {
     closeModal.current.click();
    window.location.href = `/Architecture/Hr/Employees/CreateUser/${employee_id}`;
  }
  const handleTrash = async (employee_id = 1) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/delete_employee`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          employee_id: employee_id,
        }
      });
      fetchEmployeeDetails();
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  }

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const fetchEmployeeDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_employee_details`, axiosConfig);
      setEmployeeData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };

  const handleViewDetails = (id) => {
    closeModal.current.click();
    navigate(`/Architecture/Hr/Employees/Details/${id}`);
  };

  // const handleEmp = (id) => {
  //   console.log(id);
  //   navigate(`/Architecture/Hr/EmployeeProfile/${id}`);
  // };
  return (
    <>
      <div style={{ marginTop: "0.3rem" }}>
        <button className="btn-p" onClick={() => {window.location.href = "/Architecture/Hr/Employees/Create"}}>
          Add Employee
        </button>

        <div style={{ marginTop: "0.7rem", marginLeft: "0", paddingLeft: "0" }}>
          <span
            style={{ fontSize: "1rem", fontWeight: "500", margin: "0.5rem 0" }}
          >
            Find
          </span>
          <div style={{ position: "relative",width:'50%' }}>
            <input
              onChange={handleSearch}
              className="form-control"
              placeholder="Search Employees..."
              style={{
                width: "100%",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                border: "2px solid rgba(0,0,0,0.2)",
                color: "black",
              }}
              value={search}
            />

            <i
              className="fas fa-search"
              style={{
                position: "absolute",
                right: "0.4rem",
                top: "0.6rem",
                fontSize: "1.2rem",
                color: "rgba(0,0,0,0.5)",
              }}
            ></i>
          </div>
        </div>

        <div className="cards-emp">
          {employeeData && employeeData.length > 0 ? (
            employeeData
              .filter((val, index) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.full_name.toLowerCase().includes(search)
                  || val?.role.toLowerCase().includes(search);
              })
              .map((data, i) => {
                return (
                  <>
                  <div className='col-md-4'>
                    <div className="card-p d-block">
                      <div className="row">
                        <div className="col-10">
                          <div className="row align-items-center justify-content-center">
                            <div
                              className="d-flex justify-content-center align-items-center col-4 bg-primary"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "1.1rem",
                                  fontWeight: "500",
                                  color: "white",
                                }}
                              >
                                {data.full_name && data.full_name.trim().split(' ').map((part, index) => index === 0 ? part.charAt(0).toUpperCase() : part.charAt(0)).join('').toUpperCase()}
                              </p>
                            </div>
                            <div className="col-8 gap-0">
                              <p
                                className="text-primary"
                                style={{ fontSize: "1rem", fontWeight: "600" }}
                              >
                                {data.full_name}
                              </p>
                              <span>{data.role}</span>
                              <button
                              onClick={() => handleViewDetails(data.id)}
                              style={{
                                alignItems: "start",
                                fontWeight: "500",
                                fontSize: "0.9rem",
                                color: "#d14774",
                              }}
                            >
                              View full profile
                            </button>
                            </div>
                          </div>
                        </div>
                        <div className='col-2'>
                            {/* <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleEdit(data.id)}><i className='fa fa-edit'></i></button> */}
                            {/* <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleAddress(data.id)}><i className='fa fa-map-marker'></i></button> */}
                            {/* <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleUser(data.id)}><i className='fa fa-lock'></i></button> */}
                            {/* <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleTrash(data.id)}><i className='fa fa-trash'></i></button> */}
                            {/* <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleViewDetails(data.id)}><i className='fa fa-eye'></i></button> */}
                            <button type="button" class="btn btn-warning btn-sm ml-1" onClick={()=>setModalProfileData(data)} data-bs-toggle="modal" data-bs-target="#profileModal">
                              <i className='fa fa-eye'></i>
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                );
              })
          ) : (
            <></>
          )}
        </div>
      </div>
      <div class="modal fade" id="profileModal" tabindex="-1" aria-labelledby="profileModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" style={{background: 'cadetblue'}}>
              <h1 class="modal-title fs-5 text-bolder text-light" id="profileModalLabel">Employee quick view</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeModal}></button>
            </div>
            <div class="modal-body p-5">
              <div className="row align-items-center justify-content-start mb-5">
                <div
                  className="d-flex justify-content-center align-items-center col-4 bg-primary"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      color: "white",
                    }}
                  >
                    {modalProfileData.full_name && modalProfileData.full_name.trim().split(' ').map((part, index) => index === 0 ? part.charAt(0).toUpperCase() : part.charAt(0)).join('').toUpperCase()}
                  </p>
                </div>
                <div className="col-8 gap-0">
                  <p
                    className="text-primary"
                    style={{ fontSize: "1rem", fontWeight: "600" }}
                  >
                    {modalProfileData.full_name}
                  </p>
                  <p>{modalProfileData.role}</p>
                  <button
                  onClick={() => handleViewDetails(modalProfileData.id)}
                  style={{
                    alignItems: "start",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    color: "#d14774",
                  }}
                >
                  View full profile
                </button>
                </div>
              </div>
              <div className="row mt-3  ">
                  <p className='fs-5 fw-bolder'>Contact {modalProfileData.full_name}</p>
                  <a className='fs-6 fw-bold mt-3 gap-3 d-flex'  href={`mailto:${modalProfileData.email_id}`} rel="noreferrer"><i class="bi bi-envelope-open"></i>  {modalProfileData.email_id}</a>
                  <a  className='fs-6 fw-bold mt-3 gap-3 d-flex' href={`tel:+${modalProfileData.phone_code}${modalProfileData.phone_number}`} rel="noreferrer"><i class="bi bi-telephone-plus"></i>  {modalProfileData.phone_code} {modalProfileData.phone_number}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
