import React from 'react'
import ReactSession from "../../../components/ReactSession";

function PlaceOfWork() {
  return (
    <div className='mt-4'> 
    <h4>Blip setting</h4>
    <h6 className='mt-4'>Flexible clocking</h6>
    <div className="row mt-3">
      <div className='col-7'>
      <p>Let your staff clock in form anywhere without a pre-set location. ideal for freelance or flexible workers. You can open this up to all employees or choose specific employees.</p>
      <a href="/#">Please note Only administrators can adjust this setting</a>
      </div>
      <div className='col-5'>
        <label className="btn btn-info" for="option5"><input type="radio" className="" name="options-base" id="option5" autocomplete="off" checked /> All</label>
        <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="options-base" id="option6" autocomplete="off" /> Specific</label>
      </div>
    </div>
    <div className="d-flex mt-3">
      <button className='btn btn-primary'>Cancle</button>
      <button className='btn btn-secondary ms-2'>Confirm</button>
    </div>
    </div>
  )
}

export default PlaceOfWork