import React from 'react'
import ReactSession from "../../../components/ReactSession";

function AddAnnualLeave() {
  return (
    <div>
    
    <h2>Add annual leave for Kelly</h2>

    <hr style={{borderTop:'1px solid rgba(0,0,0,0.4)'}}/>

    <form>

        <div className='form-row mb-2'>
        <label className='col-5 text-dark' style={{fontSize:'1rem',fontWeight:'500'}}>Employee</label>
        <input className='form-control col-5 w-75 mt-1' type='text' placeholder='Search or select employee' />

        </div>

        <div className='form-row mb-2'>
        <label className='col-5 text-dark' style={{fontSize:'1rem',fontWeight:'500'}}>Absence type</label>
        <select className="form-select w-75" aria-label="Default select example">
        <optgroup label="Frequently used"><option value="annual-leave">Annual leave</option><option value="sickness">Sickness</option><option value="other-absence">Other</option><option value="doctors-appointment">Medical appointment</option></optgroup>

        <optgroup label="Frequently used"><option value="annual-leave">Annual leave</option><option value="sickness">Sickness</option><option value="other-absence">Other</option><option value="doctors-appointment">Medical appointment</option></optgroup>

        </select>
        </div>

        <div className='form-row mb-2'>
        <label className='col-5 text-dark' style={{fontSize:'1rem',fontWeight:'500'}}>Start</label>
        <input className='form-control col-5 mt-1 w-50' type='date' placeholder='dd/mm/yyyy' />

        </div>

        
        <div className='form-row mb-2'>
        <label className='col-5 text-dark' style={{fontSize:'1rem',fontWeight:'500'}}>End</label>
        <input className='form-control col-5 mt-1 w-50' type='date' placeholder='dd/mm/yyyy' />

        </div>

        <div className='form-row mb-2'>
        <label className='col-5 text-dark' style={{fontSize:'1rem',fontWeight:'500'}}>Notes</label>
        <textarea cols={30} rows={4} className='form-control col-5 mt-1 w-75' type='text' placeholder='Notes regarding the absence' />

        </div>

        <button className='btn btn-primary' >Add absence</button>

    </form>
    
    </div>
  )
}

export default AddAnnualLeave