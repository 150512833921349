import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactSession from "../../components/ReactSession";

import { calculateAge } from '../../utils/DateFunctions'
import moment from 'moment';

export default function TimeOffInLieu() {
    const { timeoffinlieu_id } = useParams();
    const [formData, setFormData] = useState({
        is_edit: false,
        timeoffinlieu_id: timeoffinlieu_id,
        employee_id: '',
        absence_type: '',
        absence_for: '',
        leave_type: '',
        leave_start_datetime: '',
        leave_end_datetime: '',
        total_used_toil_in_seconds: '',
        remarks: ''
    });
    const [EmployeeData, setEmployeeData] = useState([]);

    useEffect(() => {
        if (timeoffinlieu_id) {
            fetchTimeoffinLieu();
        }
        fetchEmployeeDetails()
    }, [timeoffinlieu_id]);
    const axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const fetchEmployeeDetails = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_employee_details`, axiosConfig);
          console.log("fetchEmployeeDetails:", response?.data?.data)
          setEmployeeData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
        } catch (error) {
          console.error('Error fetching employee details:', error);
        }
      };
    const fetchTimeoffinLieu = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_timeoffinlieus/fetch_single`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    id: timeoffinlieu_id,
                }
            });
            const { employee_id,absence_type,absence_for,leave_type,leave_start_datetime,leave_end_datetime,total_used_toil_in_seconds,remarks } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { employee_id: '',absence_type: '',absence_for: '',leave_type: '',leave_start_datetime: '',leave_end_datetime: '',total_used_toil_in_seconds: '',remarks: '' };
            setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, employee_id: employee_id,absence_type: absence_type,absence_for: absence_for,leave_type: leave_type,leave_start_datetime: moment(leave_start_datetime).format('YYYY-MM-DDTHH:mm'),leave_end_datetime: moment(leave_end_datetime).format('YYYY-MM-DDTHH:mm'),total_used_toil_in_seconds: total_used_toil_in_seconds,remarks: remarks });
        } catch (error) {
            console.error('Error fetching timeoffinlieus details:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSave = async () => {
        try {
            if (formData?.is_edit) {
                // Update existing employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_timeoffinlieus/update`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            } else {
                // Create new employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_timeoffinlieus/create`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            }
            alert('Timeoffinlieus saved successfully!');
        } catch (error) {
            console.error('Error saving timeoffinlieus:', error);
        }
    };
    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card card-primary'>
                    <div className='card-header'>
                        <h4>{formData?.is_edit ? 'Update HR Documentation Appointment' : 'Time off in lieu'}</h4>
                    </div>
                    <div className='card-body p-2'>

                        <div>
                            <div className='row mt-2 mb-2'>
                                <div className='col-12'>
                                    <label>Employess</label>
                                    <select className='form-select' id='employee_id' name='employee_id' value={formData?.employee_id}
                                        onChange={handleChange}>
                                        <option value={''}> - SELECT - </option>
                                        {
                                            EmployeeData && EmployeeData?.length > 0 ? 
                                            EmployeeData.map((val, index) => (
                                                <option value={val.id}>{val.full_name} ({val.email_id})</option>
                                            ))
                                            :''
                                        }
                                    </select>
                                </div>
                                <div className='form-group'>
                                <label for="absence_for" className='mt-2 mb-2'>Absense for</label>
                                <select name="absence_for" id='absence_for' className="form-select" value={formData?.absence_for} onChange={handleChange}>
                                    <option value={''}> - Select - </option>
                                    <optgroup label="Frequently used">
                                    <option value="annual-leave">Annual leave</option>
                                    <option value="sickness">Sickness</option>
                                    <option value="other-absence">Other</option>
                                    <option value="doctors-appointment">Medical appointment</option>
                                    </optgroup>
                                    <optgroup label="Other absences">
                                    <option value="adoption">Adoption</option>
                                    <option value="antenatal-leave">Antenatal leave</option>
                                    <option value="bereavement-leave">Bereavement leave</option>
                                    <option value="compassionate-leave">Compassionate leave</option>
                                    <option value="dental-appointment">Dental appointment</option>
                                    <option value="lateness">Lateness</option>
                                    <option value="maternity-leave">Maternity leave</option>
                                    <option value="parental-bereavement-leave">Parental bereavement leave</option>
                                    <option value="parental-leave">Parental leave</option>
                                    <option value="paternity-leave">Paternity leave</option>
                                    <option value="self-isolation">Self-isolation</option>
                                    <option value="shared-parental-leave">Shared parental leave</option>
                                    <option value="time-off-for-dependants">Time off for dependants</option>
                                    <option value="time-off-in-lieu">Time off in lieu</option>
                                    <option value="training-events">Training / events</option>
                                    <option value="unpaid-leave-for-medical-care">Unpaid carer's leave</option>
                                    <option value="unpaid-leave">Unpaid leave</option>
                                    </optgroup>
                                </select>
                                </div>
                                <div className='col-12'>
                                    <label for='absence_type' className='mt-2 mb-2'>Absence type</label>
                                    <select className='form-select' id='absence_type' name='absence_type' value={formData.absence_type} onChange={handleChange}>
                                        <option value={''}> - Select - </option>
                                        <option value={'CASUAL'}> CASUAL </option>
                                        <option value={'OWNMEDICAL'}> SELF MEDICAL </option>
                                        <option value={'LATENESS'}> LATENESS </option>
                                        <option value={'FAMILYMEDICAL'}> FAMILY MEDICAL </option>
                                    </select>
                                </div>
                                <div className='col-12'>
                                    <label  className='mt-2 mb-2'>Leave Type </label>
                                    <select className='form-select' id='leave_type' name='leave_type' value={formData?.leave_type}
                                        onChange={handleChange}>
                                        <option value={''}> - SELECT - </option>
                                        <option value={'FULLDAY'}> FULLDAY </option>
                                        <option value={'PARTIAL'}> PARTIAL </option>
                                    </select>
                                </div>
                            </div>
                           
                            <div className='row mt-2 mb-2'>
                                <div className='col-12'>
                                    <label>Start</label>
                                    <input type="datetime-local"
                                        className='form-control' id="leave_start_datetime" name='leave_start_datetime' value={formData?.leave_start_datetime}
                                        onChange={handleChange} />
                                </div>

                                <div className='col-12'>
                                    <label>End</label>
                                    <input type="datetime-local"
                                        className='form-control' id="leave_end_datetime" name='leave_end_datetime' value={formData?.leave_end_datetime}
                                        onChange={handleChange} />
                                </div>
                                <div className='col-12'>
                                    <label>Amount of toil taken</label>
                                    <input type='text' className='form-control' id="total_used_toil_in_seconds" name='total_used_toil_in_seconds' value={formData?.total_used_toil_in_seconds}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <label>Remarks</label>
                                <input type='text' className='form-control' id="remarks" name='remarks' defaultValue={formData?.remarks}
                                    onChange={handleChange} />
                            </div>

                        </div>


                    </div>
                    <div className='form-group'>
                        <button
                            className='btn btn-success text-white'
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
