import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// import {calculateAge} from '../../../utils/DateFunctions'

export default function CreateLeave() {
  const { employee_id, leave_id } = useParams();
  const [formData, setFormData] = useState({
    is_edit: leave_id ? true : false, 
    leave_id: leave_id,
    employee_id: employee_id, 
    absence_type: "", 
    absense_for: "", 
    leave_type: "FULLDAY", 
    leave_start_datetime: "", 
    leave_end_datetime: "", 
    total_leave_time_in_seconds: 0,
    remarks: "", 
    leave_for: 'casual'
  });
  const [EmployeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    if (leave_id) {
      fetchLeaveDetails();
    }
    fetchEmployeeDetails();
  }, [leave_id]);



  const fetchEmployeeDetails = async () => {
    try {
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_employee_details`, axiosConfig);
      console.log("fetchEmployeeDetails:", response?.data?.data)
      setEmployeeData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };

  function getDifferenceInSeconds(date1, date2) {
    // Convert dates to Unix timestamps (milliseconds since January 1, 1970)
    const timestamp1 = new Date(date1).getTime();
    const timestamp2 = new Date(date2).getTime();
  
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);
  
    // Convert milliseconds to seconds
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  
    return differenceInSeconds;
  }

  const fetchLeaveDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_leave/fetch_single`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          leave_id: leave_id,
        }
      });
      const {employee_id, absence_type, absense_for, leave_type, leave_start_datetime, leave_end_datetime, remarks } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : {};
      setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, absense_for: absense_for, leave_id: leave_id, employee_id: employee_id, absence_type: absence_type, leave_type: leave_type, leave_start_datetime: leave_start_datetime, leave_end_datetime: leave_end_datetime, remarks: remarks });
    } catch (error) {
      console.error('Error fetching team details:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    let differenceInSeconds = getDifferenceInSeconds(formData?.leave_start_datetime, formData?.leave_end_datetime)
    if(formData?.employee_id > 0) {
      try {
        if (formData?.is_edit) {
          // Update existing team
          await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_leave/update`, {
            headers: {
              'Content-Type': 'application/json',
            },
            data: {...formData, total_leave_time_in_seconds: differenceInSeconds}
          });
        } else {
          // Create new team
          await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_leave/create`, {
            headers: {
              'Content-Type': 'application/json',
            },
            data: formData
          });
        }
        alert('Team saved successfully!');
      } catch (error) {
        console.error('Error saving team:', error);
      }
    } else {
      alert("Please choose an employee first.")
    }
  };

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='card card-primary'>
          <div className='card-header'>
            <h4>{formData?.is_edit ? 'Edit Leave' : 'Add Leave'}</h4>
          </div>
          <div className='card-body p-2'>
            {
              Number(employee_id) === 0 ? 
              <div className='form-group'>
                <label for='employee_id' className='mt-2 mb-2'>Employee</label>
                <select className='form-select' id='employee_id' name='employee_id' defaultValue={formData.employee_id} onChange={handleChange}>
                  <option value={''}> - Select - </option>
                  {
                    EmployeeData && EmployeeData?.length > 0 ? 
                      EmployeeData.map((val, index) => (
                        <option value={val.id}>{val.full_name} ({val.email_id})</option>
                      ))
                    :''
                  }
                </select>
              </div>
              :''
            }
            <div className='form-group'>
              <label for='absence_type' className='mt-2 mb-2'>Absence type</label>
              <select className='form-select' id='absence_type' name='absence_type' defaultValue={formData.absence_type} onChange={handleChange}>
                <option value={''}> - Select - </option>
                <option value={'CASUAL'}> CASUAL </option>
                <option value={'OWNMEDICAL'}> SELF MEDICAL </option>
                <option value={'LATENESS'}> LATENESS </option>
                <option value={'FAMILYMEDICAL'}> FAMILY MEDICAL </option>
              </select>
            </div>
            <div className='form-group'>
              <label for="absence_for" className='mt-2 mb-2'>Absense for</label>
              <select name="absence_for" id='absence_for' className="form-select" defaultValue={formData.absense_for} onChange={handleChange}>
                <option value={''}> - Select - </option>
                <optgroup label="Frequently used">
                  <option value="annual-leave">Annual leave</option>
                  <option value="sickness">Sickness</option>
                  <option value="other-absence">Other</option>
                  <option value="doctors-appointment">Medical appointment</option>
                </optgroup>
                <optgroup label="Other absences">
                  <option value="adoption">Adoption</option>
                  <option value="antenatal-leave">Antenatal leave</option>
                  <option value="bereavement-leave">Bereavement leave</option>
                  <option value="compassionate-leave">Compassionate leave</option>
                  <option value="dental-appointment">Dental appointment</option>
                  <option value="lateness">Lateness</option>
                  <option value="maternity-leave">Maternity leave</option>
                  <option value="parental-bereavement-leave">Parental bereavement leave</option>
                  <option value="parental-leave">Parental leave</option>
                  <option value="paternity-leave">Paternity leave</option>
                  <option value="self-isolation">Self-isolation</option>
                  <option value="shared-parental-leave">Shared parental leave</option>
                  <option value="time-off-for-dependants">Time off for dependants</option>
                  <option value="time-off-in-lieu">Time off in lieu</option>
                  <option value="training-events">Training / events</option>
                  <option value="unpaid-leave-for-medical-care">Unpaid carer's leave</option>
                  <option value="unpaid-leave">Unpaid leave</option>
                </optgroup>
              </select>
            </div>
            {/* <div className='form-group'>
              <label for='absence_type' className='mt-2 mb-2'>Absence type</label>
              <select className='form-select' id='absence_type' name='absence_type' defaultValue={formData.absense_for} onChange={handleChange}>
                <option value={''}> - Select - </option>
                <option value={'FULLDAY'}> FULLDAY </option>
                <option value={'PARTIAL'}> PARTIAL </option>
              </select>
            </div> */}
            <div className='form-group'>
              <label className='mt-2 mb-2'>Start Date</label>
              <input
                type='date'
                name='leave_start_datetime'
                className='form-control'
                placeholder=''
                value={formData.leave_start_datetime}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label className='mt-2 mb-2'>End Date</label>
              <input
                type='date'
                name='leave_end_datetime'
                className='form-control'
                placeholder=''
                value={formData.leave_end_datetime}
                onChange={handleChange}
              />
            </div>
            {/* <div className='form-group'>
              <label className='mt-2 mb-2'>Late by (In Minutes)</label>
              <input
                type='text'
                name='late_by'
                className='form-control'
                placeholder=''
                value={formData.leave_end_datetime}
                onChange={handleChange}
              />
            </div> */}
            <div className='form-group'>
              <label className='mt-2 mb-2'>Remarks</label>
              <textarea
                type='text'
                name='remarks'
                className='form-control'
                placeholder=''
                value={formData.remarks}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className='form-group text-right'>
              <button
                className='btn btn-success text-white'
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
