import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactSession from "../../../components/ReactSession";

function WhosIn() {
  const [mini, setmini] = useState(true);
  const [mini2, setmini2] = useState(true);
  const [Onabreakmini, setOnabreakmini] = useState(true);
  const [whoIsIn, setWhoIsIn] = useState([]);
  const [whoIsOut, setWhoIsOut] = useState([]);
  const [whoIsOnBreak, setWhoIsOnBreak] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchWhoisIn();
  }, []);
  const fetchWhoisIn = async () => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_clockins/fetch_today`,
        axiosConfig
      );
      if (
        response?.data &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        let clockIn = [];
        let clockOut = [];
        let onBreak = [];
        response.data.data.map((data) =>{
          if(data.current_status === 'clockin') clockIn.push(data)
          else if(data.current_status === 'break') onBreak.push(data)
          else clockOut.push(data)
        });
        setWhoIsIn(clockIn);
        setWhoIsOut(clockOut);
        setWhoIsOnBreak(onBreak)
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleClockOut = async (clock_id,employee_id) => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_clockins/clock_out`,
        {
          axiosConfig,
          data: {
            clock_id: clock_id,
            employee_id: employee_id
          },
        }
      );
      fetchWhoisIn();
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };
  const handleStartBreak = async (clock_id,employee_id) => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_clockins/start_break`,
        {
          axiosConfig,
          data: {
            clock_id: clock_id,
            employee_id: employee_id,
          },
        }
      );
      fetchWhoisIn();
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };
  const handleEndBreak = async (clock_id,employee_id) => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_clockins/end_break`,
        {
          axiosConfig,
          data: {
            clock_id: clock_id,
            employee_id: employee_id,
          },
        }
      );
      fetchWhoisIn();
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  } 
  return (
    <div className="mt-2 mb-2">
      <div className="row align-items-center">
        <div className="col-11">
          <input
            placeholder="Filter by employees"
            className="form-control"
            style={{
              border: "2px solid rgba(0,0,0,0.3)",
              width: "500px",
              color: "black",
            }}
            onChange={handleSearch}
          />
        </div>

        <div className="col-1">
          <button className="btn btn-primary">Print</button>
        </div>
      </div>

      <div className="row mt-4">
        <div className="d-flex align-items-center gap-2">
          {mini ? (
            <i
              className="fas fa-chevron-down "
              style={{ color: "#E10179", fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setmini(false)}
            ></i>
          ) : (
            <i
              className="fas fa-chevron-right"
              style={{ color: "#E10179", fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setmini(true)}
            ></i>
          )}

          <p
            className="text-dark"
            style={{ fontSize: "1.1rem", fontWeight: "500" }}
          >
            Clocked in
          </p>
        </div>

        {mini ? (
          whoIsIn && whoIsIn.length > 0 ? (
            whoIsIn
              .filter((val) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.full_name.toLowerCase().includes(search);
              })
              .map((data, index) => {
                return (
                  <div
                    className="card mt-3 mx-2 mb-2"
                    style={{
                      width: "350px",
                      boxShadow: "4px 5px 15px rgba(0,0,0,0.02)",
                      padding: "0",
                    }}
                    key={index}
                  >
                    <div className="card-body p-3">
                      <div className="row gap-3">
                        <div className="">
                          <div className="row align-items-center justify-content-center">
                            <div
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center col-4 bg-primary"
                            >
                              <p
                                style={{
                                  fontSize: "1.1rem",
                                  fontWeight: "500",
                                  color: "white",
                                }}
                              >
                                {data.full_name.trim().split(' ').map((part, index) => index === 0 ? part.charAt(0).toUpperCase() : part.charAt(0)).join('')}
                              </p>
                            </div>
                            <div className="col-8">
                              <p
                                className="text-primary"
                                style={{ fontSize: "1rem", fontWeight: "600" }}
                              >
                                {data.full_name}
                              </p>
                              <span>{data.role}</span>
                              <br />
                              <button
                                className="btn d-inline-block text-dark p-1 mt-2"
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  backgroundColor: "#FFD9ED"
                                }}
                                onClick={()=>handleStartBreak(data.id,data.employee_id)}
                              >
                                Take a Break
                              </button>
                              <button
                                className="btn d-inline-block btn-primary ms-2 p-1 mt-2"
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                }}
                                onClick={()=>handleClockOut(data.id,data.employee_id)}
                              >
                                Clock Out
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer text-dark"
                      style={{ backgroundColor: "#FFD9ED", width: "100%" }}
                    >
                      {data.start_time}, {moment(data.start_date).format("ll")}{" "}
                      | {data.notes}
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="mt-2 mb-2 pt-2 pb-2">
              No employees are currently on a break.
            </div>
          )
        ) : (
          ""
        )}
      </div>

      <div className="row mt-4">
        <div className="d-flex align-items-center gap-2">
          {Onabreakmini ? (
            <i
              className="fas fa-chevron-down "
              style={{ color: "#E10179", fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setOnabreakmini(false)}
            ></i>
          ) : (
            <i
              className="fas fa-chevron-right"
              style={{ color: "#E10179", fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setOnabreakmini(true)}
            ></i>
          )}

          <p
            className="text-dark"
            style={{ fontSize: "1.1rem", fontWeight: "500" }}
          >
            On a break
          </p>
        </div>


        {Onabreakmini ? (
          whoIsOnBreak && whoIsOnBreak.length > 0 ? (
            whoIsOnBreak
              .filter((val) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.full_name.toLowerCase().includes(search);
              })
              .map((data, index) => {
                return (
                  <div
                    className="card mt-3 mx-2 mb-2"
                    style={{
                      width: "350px",
                      boxShadow: "4px 5px 15px rgba(0,0,0,0.02)",
                      padding: "0",
                    }}
                    key={index}
                  >
                    <div className="card-body p-3">
                      <div className="row gap-3">
                        <div className="">
                          <div className="row align-items-center justify-content-center">
                            <div
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center col-4 bg-primary"
                            >
                              <p
                                style={{
                                  fontSize: "1.1rem",
                                  fontWeight: "500",
                                  color: "white",
                                }}
                              >
                                {data.full_name.trim().split(' ').map((part, index) => index === 0 ? part.charAt(0).toUpperCase() : part.charAt(0)).join('')}
                              </p>
                            </div>
                            <div className="col-8">
                              <p
                                className="text-primary"
                                style={{ fontSize: "1rem", fontWeight: "600" }}
                              >
                                {data.full_name}
                              </p>
                              <span>{data.role}</span>
                              <br />
                              <button
                                className="btn d-inline-block text-dark p-1 mt-2"
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                  backgroundColor: "#FFD9ED"
                                }}
                                onClick={()=>handleEndBreak(data.id,data.employee_id)}
                              >
                                End current Break
                              </button>
                              <button
                                className="btn d-inline-block btn-primary ms-2 p-1 mt-2"
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                }}
                                onClick={()=>handleClockOut(data.id,data.employee_id)}
                              >
                                Clock Out
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer text-dark"
                      style={{ backgroundColor: "#FFD9ED", width: "100%" }}
                    >
                      {data.start_time}, {moment(data.start_date).format("ll")}{" "}
                      | {data.notes}
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="mt-2 mb-2 pt-2 pb-2">
              No employees are currently on a break.
            </div>
          )
        ) : (
          ''
        )}
      </div>

      <div className="row mt-4">
        <div className="d-flex align-items-center gap-2">
          {mini2 ? (
            <i
              className="fas fa-chevron-down "
              style={{ color: "#E10179", fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setmini2(false)}
            ></i>
          ) : (
            <i
              className="fas fa-chevron-right"
              style={{ color: "#E10179", fontSize: "1rem", cursor: "pointer" }}
              onClick={() => setmini2(true)}
            ></i>
          )}

          <p
            className="text-dark"
            style={{ fontSize: "1.1rem", fontWeight: "500" }}
          >
            Clocked Out
          </p>
        </div>

        {mini2 ? (
          whoIsOut && whoIsOut.length > 0 ? (
            whoIsOut
              .filter((val) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.full_name.toLowerCase().includes(search);
              })
              .map((data, index) => {
                return (
                  <div
                    className="card mt-3 mb-2 mx-2"
                    style={{
                      width: "350px",
                      boxShadow: "4px 5px 15px rgba(0,0,0,0.02)",
                      padding: "0",
                    }}
                    key={index}
                  >
                    <div className="card-body p-3">
                      <div className="row gap-3">
                        <div className="">
                          <div className="row align-items-center justify-content-center">
                            <div
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: "50%",
                              }}
                              className="d-flex justify-content-center align-items-center col-4 bg-primary"
                            >
                              <p
                                style={{
                                  fontSize: "1.1rem",
                                  fontWeight: "500",
                                  color: "white",
                                }}
                              >
                                {data.full_name.trim().split(' ').map((part, index) => index === 0 ? part.charAt(0).toUpperCase() : part.charAt(0)).join('').toUpperCase()}
                              </p>
                            </div>
                            <div className="col-8">
                              <p
                                className="text-primary"
                                style={{ fontSize: "1rem", fontWeight: "600" }}
                              >
                                {data.full_name}
                              </p>
                              <span>{data.role}</span>
                              <br />
                              <span
                                className="text-primary"
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "500",
                                }}
                              >
                                Clock in
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-footer text-dark"
                      style={{ backgroundColor: "#FFD9ED", width: "100%" }}
                    >
                      {data.end_time}, {moment(data.end_date).format("ll")} |{" "}
                      {data.notes}
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="mt-2 mb-2 pt-2 pb-2">
              No employees are currently on a break.
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default WhosIn;
