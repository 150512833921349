import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function List() {
  console.clear();
  const {team_id} = useParams();
  const [employeeData, setEmployeeData] = useState([]);
  const [teamId, setTeamId] = useState(team_id);

  const InitialData = [];
  const navigate = useNavigate();

  const [search, setsearch] = useState("");
  const handleSearch = (e) => {
    setsearch(e.target.value);
  };

  useEffect(() => {
      fetchEmployeeDetails();
  }, []);
  const handleAssign = async (employee_id, team_id) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_teams/assign_team_members`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          employee_id: employee_id,
          team_id: team_id,
        }
      });
      fetchEmployeeDetails();
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  }
  const handleUnassign = async (employee_id, team_id) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_teams/unassign_team_members`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          employee_id: employee_id,
          team_id: team_id,
        }
      });
      fetchEmployeeDetails();
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  }

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
        team_id: team_id,
    }
  };
  const fetchEmployeeDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_teams/fetch_assigned_team_members`, axiosConfig);
      console.log("fetchEmployeeDetails:", response?.data?.data)
      setEmployeeData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };

  const handleEmp = (id) => {
    console.log(id);
    navigate(`/Architecture/Hr/EmployeeProfile/${id}`);
  };
  return (
    <>
      <div style={{ marginTop: "0.3rem" }}>
        <button className="btn-p" onClick={() => {window.location.href = "/Architecture/Hr/Employees/Create"}}>
          Add Employee
        </button>

        <div style={{ marginTop: "0.7rem", marginLeft: "0", paddingLeft: "0" }}>
          <span
            style={{ fontSize: "1rem", fontWeight: "500", margin: "0.5rem 0" }}
          >
            Find
          </span>
          <div style={{ position: "relative",width:'50%' }}>
            <input
              onChange={handleSearch}
              className="form-control"
              placeholder="Search Employees..."
              style={{
                width: "100%",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                border: "2px solid rgba(0,0,0,0.2)",
                color: "black",
              }}
            />

            <i
              className="fas fa-search"
              style={{
                position: "absolute",
                right: "0.4rem",
                top: "0.6rem",
                fontSize: "1.2rem",
                color: "rgba(0,0,0,0.5)",
              }}
            ></i>
          </div>
        </div>

        <div className="cards-emp">
          {employeeData && employeeData.length > 0 ? (
            employeeData
              .filter((val, index) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.full_name.toLowerCase().includes(search);
              })
              .map((data, i) => {
                return (
                  <>
                  <div className='col-md-4'>
                    <div className="card-p">
                      <div className='Avatar'>
                        <i className="fas fa-user"></i>
                      </div>
                      <div className='' style={{width: '100%'}}>
                        <div className='container-fluid text-right'>
                          <button className='btn btn-sm ml-1 p-absolute ' style={{marginLeft: "-10px"}} onClick={(e) => data.is_assigned ? handleUnassign(data.id, teamId) : handleAssign(data.id, teamId)}><i className={`fa fa-star ${data.is_assigned ? "fg-green" : "fg-theme"}`}></i></button>
                        </div>
                        <h3>{data.full_name}</h3>
                        <span>{data.role}</span>
                      </div>
                    </div>
                  </div>
                  </>
                );
              })
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
