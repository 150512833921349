import React from 'react'
// import TeamCreate from './Create'
import TeamList from './List'

export default function Index() {
  return (
    <>
      {/* <TeamCreate /> */}
      <TeamList />
    </>
  )
}
