import React from "react";
import ReactSession from "../../../components/ReactSession";

function AddJobs() {
  return (
    <div>
      <h2>
        {" "}
        <i className="fas fa-user-friends"></i> Recruitment
      </h2>

      <hr style={{ borderTop: "1px solid rgba(0,0,0,0.4)" }} />
      <nav className="mt-1 mb-1" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Jobs</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add
          </li>
        </ol>
      </nav>

      <form>
      <h5 className="w-100 p-2 mb-1" style={{backgroundColor:'rgba(0,0,0,0.1)'}} >Add job</h5>
      
        <div className="form-row mb-2">
          <label
            className="col-5 text-dark"
            style={{ fontSize: "1rem", fontWeight: "500" }}
          >
            Job title
          </label>
          <input
            className="form-control col-5 w-75 mt-1"
            type="text"
            required
          />
        </div>


        
        <div className="form-row mb-2">
          <label
            className="col-5 text-dark"
            style={{ fontSize: "1rem", fontWeight: "500" }}
          >
            Job description
          </label>
          <textarea
            cols={30}
            rows={4}
            className="form-control col-5 mt-1 w-75"
            type="text"
          />
        </div>

        <div className="form-row mb-2">
          <label
            className="col-5 text-dark"
            style={{ fontSize: "1rem", fontWeight: "500" }}
          >
            Contract type
          </label>
          <select className="form-select w-75" aria-label="Default select example">
          <option disabled="" value="">Please choose a job type</option><option value="Full-Time">Full-Time</option><option value="Part-Time">Part-Time</option><option value="Fixed-Term">Fixed-Term</option><option value="Casual">Casual</option><option value="Voluntary">Voluntary</option><option value="Freelance">Freelance</option><option value="Temporary">Temporary</option>
          </select>
        </div>

        <div className="form-row mb-2">
          <label
            className="col-5 text-dark"
            style={{ fontSize: "1rem", fontWeight: "500" }}
          >
            Experience in years
          </label>
          <input type="number" className="form-control w-75" />
          
        </div>

        
        <div className="form-row mb-2">
          <div className="row">
          <div className="col-5">

          <label
            className="col-5 text-dark"
            style={{ fontSize: "1rem", fontWeight: "500" }}
          >
            Show pay by
          </label>
          <select className="form-select w-75" aria-label="Default select example">
          <option value="Exact">Exact</option><option value="Range">Range</option><option value="Not Specified">Not specified</option>
          </select>

          </div>

          <div className="col-5">


          <label
            className="col-5 text-dark"
            style={{ fontSize: "1rem", fontWeight: "500" }}
          >
            Currency
          </label>
          <select className="form-select w-75" aria-label="Default select example">
          <option value="AUD">AUD</option><option value="NZD">NZD</option><option value="CAD">CAD</option><option value="EUR">EUR</option><option value="GBP">GBP</option>
          </select>

          </div>

          </div>
        </div>


        
        <div className="form-row mb-2">
          <div className="row">
          <div className="col-5">

          <label
            className="col-5 text-dark"
            style={{ fontSize: "1rem", fontWeight: "500" }}
          >
            Amount
          </label>
          <input type="number" className="form-control" />

          </div>

          <div className="col-5">


          <label
            className="col-5 text-dark"
            style={{ fontSize: "1rem", fontWeight: "500" }}
          >
            Rate
          </label>
          <select className="form-select w-75" aria-label="Default select example">
          <option value="Hourly">Hourly</option><option value="Daily">Daily</option><option value="Annually">Annually</option>
          </select>

          </div>

          </div>
        </div>



        <button className="btn btn-primary">Save</button>
      </form>
    </div>
  );
}

export default AddJobs;
