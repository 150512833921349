import React, { useEffect, useState } from 'react'
import {format, addDays, startOfWeek} from 'date-fns'
import moment from 'moment';
import axios from 'axios';
import aggregateWeekdayCounts from './function/getTotalLeaveFromWeek'
import ReactSession from "../../../components/ReactSession";
export default function PropleOverview() {
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const startDate = startOfWeek(new Date(), { weekStartsOn: 0 });

    const weekDates = weekDays.map((day, index) => {
        const date = addDays(startDate, index);
        return format(date, 'MM/dd/yyyy');
      });
      const [totalLeave,setTotalLeave] = useState({
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
        Saturday: 0,
        Sunday: 0,
      })
      
      const fetChPeopleOverview = async () => {
        try {
            const response = await axios.post(
            `${process.env.REACT_APP_NODEAPI_URL}hr_leave/fetch`,{
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (
            response?.data &&
            response?.data?.data &&
            response?.data?.data?.length > 0
            ) {
                const aggregatedWeekdayCounts = aggregateWeekdayCounts(response?.data?.data);
                setTotalLeave(aggregatedWeekdayCounts)
            }
        } catch (error) {
            console.error("Error fetching employee details:", error);
        }
      }
      useEffect(() => {
        fetChPeopleOverview()
      },[])
  return (
    <div className="saction">
          <div className="d-flex align-items-center justify-content-between p-2">
            <h4>People Overview</h4>
            <button className="btn btn-danger btn-p">+ Add time off</button>
          </div>
          <div className="d-flex align-items-center justify-content-between p-2 mb-4">
            {weekDays.map((day, index) => (
                <div className="cl-text" key={day}>
                        {moment(weekDates[index]).format("ddd DD MMM")}  
                    <div className={`text-center cl-box-c ${moment(new Date()).isSame(weekDates[index], 'day')?'active':''}`}>
                        <div className="text-center cl-box">{totalLeave[day]}</div>
                    </div>
                    <div className={moment(new Date()).isSame(weekDates[index], 'day')?'shape':''}></div>
                </div>
            ))}
          </div>
          <div className="blue-box p-2 mt-4">
            <ul className="nav nav-underline">
              <li className="nav-item">
                <button class="nav-link active text-dark" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">My summary</button>
              </li>
              <li className="nav-item">
                <button class="nav-link text-dark" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Working status</button>
              </li>
              <li className="nav-item">
              <button class="nav-link text-dark" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Who's clocked in <div className="badge text-bg-primary">1</div></button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <div className="row mt-3">
                  <div className="col-md-2">
                    <div className="cl-text">
                      <div className="text-center cl-box-c active">
                      <div className="text-center cl-box "><b>0</b></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                    <div className="col-md-6"><button className="btn btn-info w-100 mb-2 text-start"><i class="bi bi-brightness-high"></i> 0 Annual leave</button></div>
                    <div className="col-md-6"><button className="btn btn-info w-100 mb-2 text-start"><i class="bi bi-calendar-week"></i> 0 Other</button></div>
                    <div className="col-md-6"><button className="btn btn-info w-100 mb-2 text-start"><i class="bi bi-bandaid"></i> 0 Sickness</button></div>
                    <div className="col-md-6"><button className="btn btn-info w-100 mb-2 text-start"><i class="bi bi-clock-history"></i> 0 Time off in lieu</button></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">...</div>
              <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">...</div>
              <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">...</div>
            </div>
          </div>
        </div>
  )
}
