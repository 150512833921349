import React from 'react'
// import EmployeeCreate from './Create'
import EmployeeList from './List'

import './style.css'

export default function Index() {
  return (
    <>
      {/* <EmployeeCreate /> */}
      <EmployeeList />
    </>
  )
}
