import React, { useState, useEffect, useRef } from "react";
import "./Settings.css";
import Company from "./components/Company";
import Permission from "./components/Permission";
import WorkingPatterns from "./components/WorkingPatterns";
import PlaceOfWork from "./components/PlaceOfWork";
import Labs from "./components/Labs";
import Notification from "./components/Notification";
import Holidays from "./components/Holidays";
import ReactSession from "../../components/ReactSession";

function ClockIns() {
  const btns = useRef(null);

  const default_tabs = [
    {
      id: "Company",
    },
    {
      id: "Permission",
    },

    {
      id: "Working time patterns",
    },

    {
      id: "Notification",
    },

    {
      id: "Place of work",
    },

    // {
    //   id: "Labs",
    // },
    { id: "Public holidays" },
  ];

  const [Panel, setPanel] = useState("");

  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    setPanel("Company");

    const updateDateTime = () => {
      const now = new Date();

      // Get hours and minutes
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Get day of the week
      const dayOfWeek = now.toLocaleDateString("en-US", { weekday: "short" });

      // Get day of the month with suffix
      const day = now.getDate();
      const daySuffix = (day) => {
        if (day > 3 && day < 21) return "th"; // catch 11th, 12th, 13th
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      // Get month
      const month = now.toLocaleDateString("en-US", { month: "short" });

      // Format the date and time
      const formattedDateTime = `${hours}:${minutes} - ${dayOfWeek} ${day}${daySuffix(
        day
      )} ${month}`;

      // Update the state
      setDateTime(formattedDateTime);
    };

    // Initial call to set the date and time
    updateDateTime();

    // Update the date and time every minute
    const intervalId = setInterval(updateDateTime, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleBtns = (btn) => {
    console.log(btn);
    console.log(btns.current.innerText);

    setPanel(btn.id);

    console.log(document.getElementsByClassName("btn-b"));
    const arr = [...document.getElementsByClassName("btn-b")];
    console.log(arr);
    arr.forEach((ele) => {
      ele.style.fontWeight = "400";
      ele.style.borderBottom = "none";
    });
    document.getElementById(`${btn.id}`).style.fontWeight = "600";
    document.getElementById(`${btn.id}`).style.borderBottom =
      "2px solid #073a5d";

    // document.getElementById(`${btn.id}`).style.color = '600'
  };

  return (
    <div className="pb-2">
      <h2 className="d-flex align-items-center gap-2 text-dark">Settings</h2>

      <div className="emp_p">
        <div className="emp_p_r">
          <div className="btns-emp">
            {default_tabs.map((tab, index) => {
              return (
                <>
                  {/* onClick={()=>(window.location.href=`/Architecture/Employees/${btn.link}`)} */}
                  <button
                    className="btn-b"
                    key={index}
                    onClick={() => handleBtns(tab)}
                    ref={btns}
                    id={tab.id}
                  >
                    {tab.id}
                  </button>
                </>
              );
            })}
          </div>
          {Panel === "Company" ? (
            <Company />
          ) : Panel === "Permission" ? (
            <Permission />
          ) : Panel === "Working time patterns" ? (
            <WorkingPatterns />
          ) : Panel === "Notification" ? (
            <Notification />
          ) : Panel === "Place of work" ? (
            <PlaceOfWork />
          ) : Panel === "Labs" ? (
            <Labs />
          ) : Panel === "Public holidays" ? (
            <Holidays />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ClockIns;
