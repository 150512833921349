import React from 'react'
import ReactSession from "../../../components/ReactSession";

function AllFolders() {
    return (
        <div>
        <h2>Documents</h2>
    
        <nav className='mt-3 mb-3' aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item text-dark">All folders</li>
      </ol>
    </nav>
    
    <div className='row mt-2 mb-2'>
    
    <div className='col-9'>
    
    <input placeholder="Search all folders..." className="form-control" style={{border:'2px solid rgba(0,0,0,0.3)',width: "500px",color:"black"}} />
    
    </div>
    
    <div className='col-3'>
    
    <div className='d-flex gap-1 justify-content-center align-items-center'>
        <span className='' style={{fontSize:'1rem',}}>View</span>
        <select className='form-select'>
            <option>10</option>
            <option>20</option>
        </select>
        <span style={{fontSize:'1rem',width:'100%'}}>per page</span>
    </div>
    
    </div>
    
    </div>
    
    
    <div className='row'>
    
    <div className='col-7'>
    
    <button type="button" className="btn btn-primary" disabled>Download</button>
    
    </div>
    
    <div className='col-5 '>
    
    <div className='d-flex gap-1' >
    
    <button className='btn btn-primary col-4 ' style={{fontSize:'0.9rem'}}> Create report</button>
    
    
    </div>
    
    </div>
    
    </div>
    
    
{/*     
    <div className='p-2 row mt-4 '>
    <div  className='col-12 text-center d-flex flex-column align-items-center justify-content-center'>
    <div className='d-flex justify-content-center align-items-center' style={{width:'100px',height:'100px',borderRadius:'50%',backgroundColor:'rgba(0,0,0,0.1)'}}>
    <i className="fas fa-server " style={{fontSize:'3.5rem',color:'rgba(0,0,0,0.3)'}}></i>
    </div>
    
    <p style={{fontSize:'1.1rem'}} className='mt-2'>This folder is empty</p>
    </div>
    
    </div> */}


    
    <div className="mt-3">
        <table className="table table-striped">
          <thead>
            <tr style={{fontSize:'0.9rem'}}>
              <th scope="col" style={{color:'#073a5d',backgroundColor:'#EBF7FF',borderBottom:'2px solid rgba(0,0,0,0.2)'}}>Name</th>
              <th scope="col" style={{color:'#073a5d',backgroundColor:'#EBF7FF',borderBottom:'2px solid rgba(0,0,0,0.2)'}}>Type</th>
              <th scope="col" style={{color:'#073a5d',backgroundColor:'#EBF7FF',borderBottom:'2px solid rgba(0,0,0,0.2)'}}>Size</th>
              <th scope="col" style={{color:'#073a5d',backgroundColor:'#EBF7FF',borderBottom:'2px solid rgba(0,0,0,0.2)'}}>Date created</th>
            </tr>
          </thead>
          <tbody style={{fontSize:'0.9rem'}}>
            <tr>
              {/* <th scope="row">1</th> */}
              <td className="text-primary" style={{fontWeight:'500'}}> <i className="far fa-folder"></i> Hr Documents</td>
              <td className="text-dark">Folder</td>
              <td className="text-dark"></td>
              <td >
                
              </td>
            </tr>


            <tr>
              {/* <th scope="row">1</th> */}
              <td className="text-primary" style={{fontWeight:'500'}}> <i className="far fa-folder"></i> My Documents</td>
              <td className="text-dark">Folder</td>
              <td className="text-dark"></td>
              <td >
                
              </td>
            </tr>
           



         
            
          </tbody>
        </table>
      </div>
    
        
        
        </div>
      )
}

export default AllFolders