import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

export default function Emergencies() {
  const {employee_id} = useParams();
  const modalClose = React.useRef(null);
  const [isPersonalInfo, setIsPersonalInfo] = useState(false)
  const [newFormData, setNewFormData] = useState({
    employee_id: employee_id,
    first_name: '',
    last_name: '',
    work_phone: '',
    address_1: '',
    address_2: '',
    address_3: '',
    country: '',
    city_name: '',
    county: '',
    postcode: '',
    relationship: '',
    primary_contact: 0,
  })
  const [employeeContact, setEmployeeContact] = useState([])
  useEffect(() => {
    fetchEmergencies()
  }, [])
  const handleNewChange = (e)=>{
    const {name , value} = e.target
    setNewFormData({...newFormData,[name]: value})
  }
  const onEdit = (e)=>{
    const {name , value} = e.target
    setIsPersonalInfo({...isPersonalInfo,[name]: value})
  }
  const onEditChecked = (e) => {
    const { name } = e.target;
    const isValue = Number(isPersonalInfo[name]) === 0 ?1:0
    setIsPersonalInfo({ ...isPersonalInfo, [name]: isValue });
  };
  const handleChangeChecked = (e) => {
    const { name } = e.target;
    const isValue = newFormData[name] === 0 ?1:0
    setNewFormData({ ...newFormData, [name]: isValue });
  };
  const fetchEmergencies = async () =>{
    try {
      const res = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_emergencies`, {
        headers: {
            'Content-Type': 'application/json',
        },
        data: {employee_id: employee_id}
    });
    if(res.data && res.data?.data.length>0){
      setEmployeeContact(res.data.data)
    }else setEmployeeContact([])
  } catch (error) {
      console.error('Error fetching employee details:', error);
  }
  }
  const handleNewSaved = async () => {
    try {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
            data: newFormData
        };
       await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/create_new_contact`, axiosConfig);
       modalClose.current?.click()
       fetchEmergencies()
    } catch (error) {
        console.error('Error fetching employee details:', error);
    }
  };
  const handleUpdate = async () => {
    try {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
            data: isPersonalInfo
        };
       await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_new_contact`, axiosConfig);
       fetchEmergencies()
       setIsPersonalInfo([])
    } catch (error) {
        console.error('Error fetching employee details:', error);
    }
  };
  const deleteContact = async () => {
    try {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
            data: isPersonalInfo
        };
       await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/delete_new_contact`, axiosConfig);
       fetchEmergencies()
       setIsPersonalInfo([])
    } catch (error) {
        console.error('Error fetching employee details:', error);
    }
  };
  const cancleContact = () => setIsPersonalInfo([]);
  return (
    <>
      <button className='btn btn-danger my-2 ' data-bs-toggle="modal" data-bs-target="#AddNewContact">Add new contact</button>

        <div className="accordion" id="accordionExample">
        {employeeContact && employeeContact.length > 0? employeeContact.map((item,index)=>{
          return <div className="accordion-item my-2" key={index}>
            <h2 className="accordion-header">
              <div className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsePersonal-${item.id}`} aria-expanded="true" aria-controls={`collapsePersonal-${item.id}`}>
                <div className='w-100'>
                  <h5 className='text-dark fw-bold'>{item.first_name} {item.last_name} {item.primary_contact > 0 ? <span class="badge rounded-pill border border-secondary text-dark fw-light">Primary contact</span>:''}</h5> 
                </div>
              </div>
            </h2>
            <div id={`collapsePersonal-${item.id}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <div className='col-12 text-right'>
                  <button className=" text-primary fw-bold" onClick={()=>setIsPersonalInfo(item)}>Edit</button>
                </div>
                {isPersonalInfo.id === item.id?
                  <div className="row">
                    <label htmlFor="first_name" className=' my-2 col-2'>First name</label>
                    <div className="col-10  my-2">
                      <input type="text" name="first_name" value={isPersonalInfo?.first_name} onChange={onEdit} id="first_name" placeholder='First name' className="w-25 form-control" />
                    </div>

                    <label htmlFor="last_name" className=' my-2 col-2'>Last name</label>
                    <div className="col-10  my-2">
                      <input type="text" name="last_name" value={isPersonalInfo?.last_name} onChange={onEdit} id="last_name" placeholder='Last name' className="w-25 form-control" />
                    </div>

                    <label htmlFor="work_phone" className=' my-2 col-2'>Work phone</label>
                    <div className="col-10  my-2">
                      <input type="text" name="work_phone" value={isPersonalInfo?.work_phone} onChange={onEdit} id="work_phone" placeholder='Work phone' className="w-25 form-control" />
                    </div>
                    
                    <label htmlFor="country" className=' my-2 col-2'>Country</label>
                    <div className="col-10  my-2">
                      <input type="text" name="country" value={isPersonalInfo?.country} onChange={onEdit} id="country" placeholder='Country' className="w-25 form-control" />
                    </div>

                    <label htmlFor="address_1" className=' my-2 col-2'>Address 1</label>
                    <div className="col-10  my-2">
                      <input type="text" name="address_1" value={isPersonalInfo?.address_1} onChange={onEdit} id="address_1" placeholder='Address 1' className="w-25 form-control" />
                    </div>

                    <label htmlFor="address_2" className=' my-2 col-2'>Address 2</label>
                    <div className="col-10  my-2">
                      <input type="text" name="address_2" value={isPersonalInfo?.address_2} onChange={onEdit} id="address_2" placeholder='Address 2' className="w-25 form-control" />
                    </div>

                    <label htmlFor="address_3" className=' my-2 col-2'>Address 3</label>
                    <div className="col-10  my-2">
                      <input type="text" name="address_3" value={isPersonalInfo?.address_3} onChange={onEdit} id="address_3" placeholder='Address 3' className="w-25 form-control" />
                    </div>

                    <label htmlFor="city_name" className=' my-2 col-2'>Town / City</label>
                    <div className="col-10  my-2">
                      <input type="text" name="city_name" value={isPersonalInfo?.city_name} onChange={onEdit} id="city_name" placeholder='Town / City' className="w-25 form-control" />
                    </div>

                    <label htmlFor="county" className=' my-2 col-2'>County</label>
                    <div className="col-10  my-2">
                      <input type="text" name="county" value={isPersonalInfo?.county} onChange={onEdit} id="county" placeholder='County' className="w-25 form-control" />
                    </div>

                    <label htmlFor="postcode" className=' my-2 col-2'>Postcode</label>
                    <div className="col-10  my-2">
                      <input type="text" name="postcode" value={isPersonalInfo?.postcode} onChange={onEdit} id="postcode" placeholder='Postcode' className="w-25 form-control" />
                    </div>


                    <label htmlFor="relationship" className=' my-2 col-2'>Relationship</label>
                    <div className="col-10  my-2">
                      <input type="text" name="relationship" value={isPersonalInfo?.relationship} onChange={onEdit} id="relationship" placeholder='Relationship' className="w-25 form-control" />
                    </div>

                    <label htmlFor="primary_contact" className=' my-2 col-2'>Primary contact</label>
                    <div className="col-10  my-2">
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" name="primary_contact" onChange={onEditChecked} checked={isPersonalInfo.primary_contact?true:false} id="primary_contact" role="switch" />
                      </div>
                    </div>

                    <div className="col-12">
                      <button className='btn btn-p' onClick={handleUpdate}>Save</button>
                      <button className='btn btn-info ms-2' onClick={cancleContact}>Cancle</button>
                      <button className='btn btn-primary ms-2' onClick={deleteContact}>Delete</button>
                    </div>
                  </div>
                :
                <div className="row">
                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>First name</p>
                    <span>{item.first_name?item.first_name:'Not specified'}</span>
                  </div>

                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>Last name</p>
                    <span>{item.last_name?item.last_name:'Not specified'}</span>
                  </div>

                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Work phone</p>
                    <span>{item.work_phone?item.work_phone:'Not specified'}</span>
                  </div>

                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>Relationship</p>
                    <span>{item.relationship?item.relationship:'Not specified'}</span>
                  </div>
                  
                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Address</p>
                    <span className='d-block'>{item.address_1}</span>
                    <span className='d-block'>{item.address_2}</span>
                    <span className='d-block'>{item.address_3}</span>
                    <span className='d-block'>{item.city_name}</span>
                    <span className='d-block'>{item.county}</span>
                    <span className='d-block'>{item.postcode}</span>
                  </div>

                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>Country</p>
                    <span>{item.country?item.country:'Not specified'}</span>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        })
        :
        <div className="row m-auto justify-content-center">
          <img src="https://cdn1.iconfinder.com/data/icons/empty-states-2/1080/Contact_Is_Empty-512.png" alt="empty Contact" className='w-25 h-25'/>
          <h5 className='text-center'>Contacts empty</h5>
        </div>
        } 
        
          
        </div>

        <div class="modal fade" id="AddNewContact" tabindex="-1" aria-labelledby="AddNewContactLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="AddNewContactLabel">Modal title</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={modalClose}></button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-6">
                      <label htmlFor="employee_first_name" className=' '>First name</label>
                        <input type="text" name="first_name" onChange={handleNewChange} id="employee_first_name" placeholder='First name' className="form-control" />
                  </div>
                    <div className="col-6">
                    <label htmlFor="employee_last_name" className='mt-1'>Last name</label>
                      <input type="text" name="last_name" onChange={handleNewChange} id="employee_last_name" placeholder='Last name' className="form-control" />
                    </div>
                    <div className="col-6">
                    <label htmlFor="employee_work_phone" className='mt-1'>Work phone</label>
                      <input type="text" name="work_phone" onChange={handleNewChange} id="employee_work_phone" placeholder='Work phone' className="form-control" />
                    </div>
                          <div className="col-6">
                    <label htmlFor="employee_country" className='mt-1'>Country</label>
                      <input type="text" name="country" onChange={handleNewChange} id="employee_country" placeholder='Country' className="form-control" />
                    </div>
                    <div className="col-6">
                    <label htmlFor="employee_address_1" className='mt-1'>Address 1</label>
                      <textarea type="text" name="address_1" onChange={handleNewChange} id="employee_address_1" placeholder='Address 1' className="form-control" ></textarea>
                    </div>
                    <div className="col-6">
                    <label htmlFor="employee_address_2" className='mt-1'>Address 2</label>
                      <textarea type="text" name="address_2" onChange={handleNewChange} id="employee_address_2" placeholder='Address 2' className="form-control" ></textarea>
                    </div>
                    <div className="col-6">
                    <label htmlFor="employee_address_3" className='mt-1'>Address 3</label>
                      <input type="text" name="address_3" onChange={handleNewChange} id="employee_address_3" placeholder='Address 3' className="form-control" />
                    </div>
                    <div className="col-6">
                    <label htmlFor="employee_city_name" className='mt-1'>Town / City</label>
                      <input type="text" name="city_name" onChange={handleNewChange} id="employee_city_name" placeholder='Town / City' className="form-control" />
                    </div>
                    <div className="col-6">
                    <label htmlFor="employee_county" className='mt-1'>County</label>
                      <input type="text" name="county" onChange={handleNewChange} id="employee_county" placeholder='County' className="form-control" />
                    </div>
                    <div className="col-6">
                    <label htmlFor="employee_postcode" className='mt-1'>Postcode</label>
                      <input type="text" name="postcode" onChange={handleNewChange} id="employee_postcode" placeholder='Postcode' className="form-control" />
                    </div>

                    <div className="col-6">
                    <label htmlFor="employee_relationship" className='mt-1'>Relationship</label>
                      <input type="text" name="relationship" onChange={handleNewChange} id="employee_relationship" placeholder='Relationship' className="form-control" />
                    </div>
                    <div className="col-12 mt-3 d-flex">
                        <label htmlFor="employee_primary_contact" className='fw-bold me-3'>Primary contact</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" name="primary_contact" onChange={handleChangeChecked} id="employee_primary_contact" role="switch" />
                      </div>
                    </div>
                  </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" onClick={handleNewSaved}>Save changes</button>
              </div>
            </div>
          </div>
        </div>


    </>
  )
}
