import React from 'react'

import AnnualLeaveBalance from './Stats'

export default function Index() {
  return (
    <div className='container-fluid'>
        <AnnualLeaveBalance />
    </div>
  )
}
